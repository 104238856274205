@import "bootstrapCustom";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

// "homepage": "https://ngf93.github.io/purchases",

:root {
  --h-height: 85px;
  --f-height: 80px;
  @include media-breakpoint-down(md) {
    --h-height: 70px;
  }
  @include media-breakpoint-down(sm) {
    --h-height: 55px;
    --f-height: 64px;
  }
}

$main-font: "Open Sans";
$title-font: "Bebas";
$pale-blue: #9dc4ef;
$blue: #4a83dc;
$light-blue: #66c6e4;
$dark-blue: #0d59ab;
$gradient-1: linear-gradient(120.98deg, $blue 48.77%, #3266b8 85.89%);
$gradient-2: linear-gradient(
  120.98deg,
  rgba(72, 216, 104, 0.8) 48.77%,
  rgba(120, 216, 72, 0.8) 85.89%
);
$glass: linear-gradient(
    92.32deg,
    rgba(255, 255, 255, 0) 16.31%,
    rgba(245, 251, 255, 0.3) 97.01%
  ),
  rgba(255, 255, 255, 0.3);
$dark-green: #005a47;
$green: #48d868;
$pale-green: #94e5a6;
$aqua: rgba(67, 185, 198, 0.26);
$rose: #ff5630;

$white: #ffffff;
$extrapale-gray: #ecf4fc;
$pale-gray: #d9d9d9;
$light-gray: #c1c1c1;
$gray: #8f8f8f;
$black: #1b1b1c;
$total-black: #000;
$transition: 300ms ease-in-out;
$radius: 0.67em;

/* mixins */
@mixin flex($dir: row, $x: center, $y: center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  justify-content: $x;
  align-items: $y;
  flex-wrap: $wrap;
}
@mixin no-appearance {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
}
@mixin safariFix {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
@mixin absBG($z: 1, $top: 0%, $h: 100%) {
  position: absolute;
  top: $top;
  left: 0px;
  width: 100%;
  height: $h;
  z-index: $z;
}
@mixin clamp($count, $line, $strict: false) {
  display: -webkit-box;
  -webkit-line-clamp: $count;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line;
  @if $strict {
    height: $line * $count;
  } @else {
    max-height: $line * $count;
  }
}
@mixin list {
  list-style: none;
  padding-left: 0px;
}
@mixin shadow {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
    0px 4px 15px rgba(100, 100, 100, 0.08);
  backdrop-filter: blur(4px);
}
@mixin boxShadow($size: 15px) {
  box-shadow: 0px 4px $size rgba(100, 100, 100, 0.08);
}
@mixin lightShadow {
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
}
@mixin shadowMobile {
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5),
    1px 1px 10px rgba(100, 100, 100, 0.15);
  backdrop-filter: blur(2.5px);
}
@mixin blob {
  box-shadow: inset 0px 1px 3px rgba(245, 201, 249, 0.47);
  filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
}
@mixin btnStyle {
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3),
    0px 3px 10px rgba(156, 156, 156, 0.2);
}
@mixin blurShadow {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
    0px 5px 25px rgba(95, 95, 95, 0.08);
  backdrop-filter: blur(5px);
}
@mixin bgTextureGradient(
  $grad:
    linear-gradient(109.93deg, #3399ab 26.37%, #78d848 59.52%, #91ee8f 91.99%)
) {
  background: url(imgs/bg/texture.png), $grad;
  background-size: cover, 100% 100%;
  background-repeat: no-repeat;
  background-blend-mode: overlay, normal;
}
@mixin placeholder($color, $fw: 400) {
  &:-moz-placeholder,
  &::-moz-placeholder,
  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-ms-input-placeholder {
    color: $color;
    font-weight: $fw;
  }
  &::placeholder {
    color: $color;
    font-weight: $fw;
  }
}
@mixin rounedImg($w) {
  width: $w;
  height: $w;
  border-radius: 50%;
  object-fit: cover;
}
@mixin roundedImg($w, $rad: 50%, $fit: cover) {
  width: $w;
  height: $w;
  border-radius: $rad;
  object-fit: $fit;
}
@mixin textEllipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/*****************
SCROLL STYLIZATION
******************/
html {
  scrollbar-color: $pale-blue transparent;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: $white;
  @include shadow();
  &:hover,
  &:active {
    background: rgba($color: $pale-blue, $alpha: 0.2);
  }
}
::-webkit-scrollbar-thumb {
  background-color: $pale-blue;
  border-radius: 5px;
  transition: 0.2s;
  &:hover {
    background-color: $light-blue;
  }
  &:active {
    background-color: $blue;
  }
}
::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

/* Typography */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
button,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
  line-height: 135%;
  margin-bottom: 0px;
}

h1,
.h1 {
  font-family: $title-font;
  font-size: 3.6em;
  font-weight: normal;
  text-align: left;
  line-height: 120%;
  margin-bottom: 0.2em;
  @include media-breakpoint-down(xl) {
    font-size: 3.5em;
  }
  @include media-breakpoint-down(lg) {
    font-size: 3.2em;
  }
  @include media-breakpoint-down(md) {
    font-size: 2.75em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.3em;
  }
}
h2,
.h2 {
  font-family: $title-font;
  font-size: 3em;
  font-weight: normal;
  text-align: left;
  margin-bottom: 0.67em;
  @include media-breakpoint-down(md) {
    font-size: 2.75em;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.3em;
    margin-bottom: 0.5em;
  }
}
h3 {
  font-size: 1.6em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0.2em;
  @include media-breakpoint-down(lg) {
    font-size: 1.4em;
  }
}
h4 {
  font-size: 1.3em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.2em;
  @include media-breakpoint-down(lg) {
    font-size: 1.2em;
  }
  @include media-breakpoint-down(md) {
    font-size: 1.1em;
  }
}
h5 {
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
  @include media-breakpoint-down(lg) {
    font-size: 1.1em;
  }
  @include media-breakpoint-down(md) {
    font-size: 1em;
  }
}
h6 {
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}
.topic {
  background-color: $blue;
  color: $white;
  padding: 1em 2em;
  border-radius: $radius;
  h1,
  h2,
  a {
    line-height: 100%;
  }
}
.fs {
  &-08 {
    font-size: 0.8em;
  }
  &-09 {
    font-size: 0.9em;
  }
  &-10 {
    font-size: 1em;
  }
  &-11 {
    font-size: 1.1em;
  }
  &-12 {
    font-size: 1.2em;
  }
  &-13 {
    font-size: 1.3em;
  }
  &-14 {
    font-size: 1.4em;
  }
  &-15 {
    font-size: 1.5em;
  }
  &-16 {
    font-size: 1.6em;
  }
  &-17 {
    font-size: 1.7em;
  }
  &-18 {
    font-size: 1.8em;
  }
  &-19 {
    font-size: 1.9em;
  }
  &-20 {
    font-size: 2em;
  }
  &-25 {
    font-size: 2.5em;
  }
  &-30 {
    font-size: 3em;
  }
  &-35 {
    font-size: 3.5em;
  }
  &-40 {
    font-size: 4em;
  }
}
.fw {
  &-3 {
    font-weight: 300;
  }
  &-4 {
    font-weight: 400;
  }
  &-5 {
    font-weight: 500;
  }
  &-6 {
    font-weight: 600;
  }
  &-7 {
    font-weight: 700;
  }
  &-8 {
    font-weight: 800;
  }
  &-9 {
    font-weight: 900;
  }
}
.green {
  color: $green;
}
.white {
  color: $white;
}
.light-gray {
  color: $light-gray;
}
.gray {
  color: $gray;
}
.pale-blue {
  color: $pale-blue;
}
.blue {
  color: $blue;
}
.dark-blue {
  color: $dark-blue;
}
.rose {
  color: $rose;
}
.black {
  color: $black;
}
.total-black {
  color: $total-black;
}
.title-font {
  font-family: $title-font;
}
.bg-blue {
  background-color: $blue;
}
.ruble {
  border-radius: 50%;
  border: 1px solid $white;
}
.lh-n {
  line-height: normal;
}

/* Form elements */
input,
textarea {
  background-color: rgba($white, $alpha: 0.8);
  border: 1px solid $light-gray;
  border-radius: $radius;
  padding: 0.725em 1.65em;
  width: 100%;
  outline: none;
  display: block;
  transition: $transition;
  @include placeholder($light-gray, 400);
  &.p-blue {
    border: 1px solid $pale-blue;
    @include placeholder($pale-blue, 400);
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $blue;
  }
}
input {
  &[type="checkbox"] {
    @include no-appearance();
    width: 20px;
    height: 20px;
    border: 1px solid $green;
    border-radius: 4px;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 40%;
      border-color: transparent;
      border-width: 0px 0px 3px 3px;
      border-style: solid;
      transform: translate(-50%, -65%) rotate(-45deg);
      transition: border-color $transition;
    }

    &:checked {
      &::before {
        border-color: $green;
      }
    }
  }
  &[type="radio"] {
    @include no-appearance;
    width: 20px;
    height: 20px;
    border: 1px solid $green;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: none;
    padding: 0px;
    margin: 0px;
    transition: $transition;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 4px;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $green;
      transition: $transition;
      opacity: 0;
    }
    &:checked::before {
      opacity: 1;
    }
  }
  &.switch {
    @include no-appearance;
    width: 40px;
    height: 20px;
    border: none;
    border-radius: 10px;
    background-color: $light-gray;
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 3px;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: $white;
      transition: $transition;
      opacity: 1;
    }
    &:checked {
      background-color: $green;
      &::before {
        left: calc(100% - 19px);
      }
    }
  }
  &[type="search"] {
    padding-right: 2.5em;
    background: url(imgs/SearchIcon.svg) no-repeat calc(100% - 0.67em) 50%
      rgba($white, $alpha: 0.85);
    background-size: 1.6em;
  }
  &.code {
    font-family: "Bebas";
    font-size: 2.3em;
    padding: 0px 10px;
    text-align: center;
    line-height: 120%;
  }
}
label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    flex: 1;
  }
}

.input-rating {
  input {
    display: none;
  }
  .stars {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    button {
      display: flex;
      color: $blue;
      font-size: 2.15em;
      &:not(:last-child) {
        margin-left: 0.2em;
      }
      svg {
        path {
          stroke: $blue;
          fill: $white;
          transition: $transition;
        }
      }
      &:hover,
      &.active,
      &.active ~ button,
      &:hover,
      &:hover ~ button {
        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
  }
}

.password {
  position: relative;
  input {
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    z-index: 1;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
    color: $gray;
    height: 100%;
    width: 2em;
    font-size: 1.6em;
    @include flex();
    svg {
      path {
        stroke-width: 1.25px;
      }
    }
  }
}
select {
  @include no-appearance();
  cursor: pointer;
  min-width: 250px;
  border-radius: $radius;
  padding: 0.75em 1.65em;
  border: none;
  @include boxShadow(12px);
  background: url(imgs/chevron-down.svg), $glass;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.67em) 50%, 50% 50%;
  background-size: 1.6em, cover;
  transition: $transition;
  &:hover,
  &:focus {
    background-color: rgba($pale-blue, 0.1);
  }
  @include media-breakpoint-down(md) {
    min-width: 200px;
  }
}
.labeled-input {
  position: relative;
  label {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 1em;
    transform: translateY(-60%);
    width: fit-content;
    background-color: $white;
    color: $gray;
    padding: 0 0.67em;
  }
  input {
    position: relative;
    z-index: 1;
    border-radius: $radius;
    &:hover + label,
    &:focus + label,
    &:active + label {
      color: $blue;
    }
  }
  select {
    border-radius: $radius;
    width: 100%;
    border: 1px solid $light-gray;
    box-shadow: none;
    background: url(imgs/chevron-down-gray.svg) transparent;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.67em) 50%;
    background-size: 1.6em;
    min-width: 150px;
    &:hover,
    &:focus,
    &:active {
      background: url(imgs/chevron-down.svg) transparent;
      background-repeat: no-repeat;
      background-position: calc(100% - 0.67em) 50%;
      background-size: 1.6em;
      border: 1px solid $blue;
    }
  }
  &:hover,
  &:focus,
  &:active {
    label {
      color: $blue;
    }
  }
}
.files {
  &-label {
    position: relative;
    @include flex();
    transition: $transition;
    &:hover {
      cursor: pointer;
    }
    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      width: 1px;
      height: 1px;
      top: 0px;
      left: 0px;
      padding: 0px;
    }
    .icon {
      @include flex();
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      border: 1px solid rgba($color: $pale-blue, $alpha: 0.35);
      svg {
        color: $blue;
        font-size: 1.5em;
        stroke-width: 1.25px;
      }
    }

    .ind {
      position: absolute;
      bottom: -0.25em;
      right: 0px;
      color: $blue;
      font-weight: 600;
      line-height: 1em;
      font-size: 0.9em;
      &:empty {
        display: none;
      }
    }
  }
  &-list {
    @include list();
    @include flex(row, flex-start, stretch, nowrap);
    li {
      img {
        @include roundedImg(100px, $radius, cover);
      }
    }
  }
}
.hv-100 {
  min-height: calc(100vh - var(--h-height) - var(--f-height) - 4em);
}
button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
  &[disabled]:not(.btn) {
    opacity: 0.5 !important;
  }
}
.btn {
  @include flex(row, center, center, nowrap);
  padding: 0.8em 1.6em;
  text-align: center;
  width: fit-content;
  border-radius: $radius;
  &:active,
  &:hover {
    transition: $transition;
  }
  //голубая градиентная кнопка
  &-1,
  &-primary {
    @extend .btn;
    color: $white !important;
    background-color: $blue !important;
    background-image: linear-gradient(
      150deg,
      $blue 25%,
      #3266b8 50%,
      $blue 75%
    ) !important;
    background-size: 200% 100% !important;
    background-position: left center !important;
    &:hover,
    &:focus {
      color: $white;
      background-position: right center;
    }
    &:active,
    &.active {
      color: $white !important;
      background-position: center center;
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  //белая кнопка
  &-2,
  &-light {
    @extend .btn;
    background: #ffffff;
    color: $black;
    @include blurShadow();
    border-radius: $radius;
    &:hover,
    &:focus,
    &:active,
    &.active {
      color: $black;
      background: mix($pale-blue, $white, 20%);
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  //розовая кнопка
  &-3,
  &-danger {
    @extend .btn;
    background: $rose;
    color: $white;
    @include blurShadow();
    border-radius: $radius;
    &:hover,
    &:focus {
      background: $rose;
      color: $white;
    }
    &:active,
    &.active {
      background: $rose;
      color: $white;
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  //черная кнопка
  &-4,
  &-dark {
    @extend .btn;
    background: $total-black;
    color: $white;
    @include blurShadow();
    border-radius: $radius;
    &:hover,
    &:focus {
      color: $white;
      background: $dark-blue;
    }
    &:active,
    &.active {
      color: $white;
      background: $dark-green;
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  //голубая кнопка
  &-5 {
    @extend .btn;
    background: $blue;
    color: $white;
    @include blurShadow();
    border-radius: $radius;
    &:hover,
    &:focus {
      color: $white;
      background: $dark-blue;
    }
    &:active,
    &.active {
      color: $white;
      background: $dark-green;
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  //зеленая кнопка
  &-6,
  &-success {
    @extend .btn;
    background: $green;
    color: $white;
    @include blurShadow();
    border-radius: $radius;
    &:hover,
    &:focus {
      color: $white;
      background: $light-blue;
    }
    &:active,
    &.active {
      color: $white;
      background: $dark-green;
    }
    &[disabled] {
      background: $light-gray !important;
    }
  }
  &[disabled] {
    background: $light-gray;
  }
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.input-file-simple {
  position: relative;
  color: $gray;
  transition: $transition;
  &:hover {
    cursor: pointer;
    color: $blue;
  }
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    height: 1px;
    top: 0px;
    left: 0px;
    padding: 0px;
  }
  svg {
    color: currentColor;
    font-size: 1.25em;
  }
  .ind {
    position: absolute;
    bottom: -0.75em;
    right: -0.75em;
    color: $white;
    line-height: 1em;
    font-size: 0.75em;
    background: $blue;
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    @include flex();
    &:empty {
      display: none;
    }
  }
}

.lang-switcher {
  position: relative;
  button {
    width: 100%;
    font-size: 1em;
    padding: 0.1em 0.2em;
    @include flex(row, space-between, center, nowrap);
    color: $gray;
  }
  img {
    width: 1.6em;
    height: 1.6em;
    object-fit: cover;
    border-radius: 50%;
    @include shadow();
    margin-right: 0.5em;
  }
  ul {
    @include list();
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0px;
    width: 100%;
    height: auto;
    @include flex(column, flex-start, stretch, nowrap);
    li {
      width: 100%;
      margin: 0px !important;
    }
  }
  // &.opened {

  // }
}
.star-rating {
  @include flex(row, flex-start, center, nowrap);
  color: $blue;
  font-size: 1.34em;
  svg {
    &:not(:first-child) {
      margin-left: 3px;
    }
    &.filled {
      path {
        fill: $blue;
      }
    }
  }
}
.link {
  color: $pale-blue;
  text-decoration: underline;
  transition: $transition;
  &:hover,
  &:focus,
  &:active {
    color: $dark-blue;
    text-decoration: underline;
  }
  &-2 {
    color: $blue;
    text-decoration: none;
    transition: $transition;
    &:hover,
    &:focus,
    &:active {
      color: $light-blue;
      text-decoration: none;
    }
  }
}
.return-link {
  @include flex();
  font-size: 2em;
}
.return-title {
  display: none;
  @include media-breakpoint-down(lg) {
    @include flex(row, flex-start, center, nowrap);
    margin-bottom: 2em;
  }
}

.min-250 {
  min-width: 250px;
}
@include media-breakpoint-down(sm) {
  .w-xs-100 {
    width: 100%;
  }
}
.flex-1 {
  flex: 1;
}
.mb-6 {
  margin-bottom: 6em;
  @include media-breakpoint-down(md) {
    margin-bottom: 5em;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 4em;
  }
}

/* Breadcrumbs */
.breadcrumbs {
  margin-bottom: 2em;
  color: $light-gray;
  @include media-breakpoint-down(xl) {
    margin-bottom: 1.5em;
  }
  ul {
    @include list();
    @include flex(row, flex-start, center, wrap);
    li {
      a {
        font-size: 1.2em;
        font-weight: 500;
        @include media-breakpoint-down(lg) {
          font-size: 1.1em;
        }
        @include media-breakpoint-down(md) {
          font-size: 1em;
        }
      }
      &:not(:first-child) {
        margin-left: 1em;
        padding-left: 1.4em;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0px;
          display: block;
          height: 100%;
          width: 8px;
          background: url(imgs/arrow-mini.svg) no-repeat center;
          background-size: contain;
          @include media-breakpoint-down(lg) {
            width: 7px;
          }
          @include media-breakpoint-down(md) {
            width: 6px;
          }
        }
      }
    }
  }
}

/* Pagination */
nav.pagination {
  ul {
    @include list();
    @include flex();
    li {
      &:not(:first-child) {
        margin-left: 1.3em;
      }
      a {
        width: 2em;
        height: 2em;
        border-radius: 50%;
        background-color: transparent;
        color: $blue;
        @include flex();
        transition: $transition;
        svg {
          font-size: 1.2em;
        }
        &:hover,
        &:focus {
          background-color: rgba($pale-blue, 0.15);
        }
        &:active,
        &.active {
          background-color: rgba($pale-blue, 0.3);
          @include lightShadow();
        }
      }
    }
  }
}

.accordion {
  --bs-accordion-color: #1b1b1c;
  --bs-accordion-btn-color: #4a83dc;
  --bs-accordion-active-color: #4a83dc;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-padding-y: 0.5em;
  --bs-accordion-btn-padding-x: 0px;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-border-color: rgba(157, 196, 239, 0.3);
  --bs-accordion-btn-icon-width: 2em;
  --bs-accordion-btn-icon: url(imgs/chevron-down-blue.svg);
  --bs-accordion-btn-active-icon: url(imgs/chevron-down-blue.svg);
  &-button {
    box-shadow: unset;
    font-weight: 600;
  }
}
.dropdown {
  &-toggle {
    @include flex();
    &::after {
      display: none;
    }
  }
  &-menu {
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1em;
    --bs-dropdown-color: #1c1c1c;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: #f4f6f8;
    --bs-dropdown-border-radius: 10px;
    --bs-dropdown-border-width: 0px;
    --bs-dropdown-inner-border-radius: 9px;
    --bs-dropdown-link-color: #1c1c1c;
    --bs-dropdown-link-hover-color: #1c1c1c;
    --bs-dropdown-link-hover-bg: #f4f6f8;
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #4a83dc;
    --bs-dropdown-item-padding-x: 1em;
    --bs-dropdown-item-padding-y: 0.5em;
    --bs-dropdown-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
    box-shadow: var(--bs-dropdown-box-shadow);
    padding: 0px;
    overflow: hidden;
    @include safariFix();
  }
  &-item {
    padding: 0px;
    a {
      display: block;
      padding: var(--bs-dropdown-item-padding-y)
        var(--bs-dropdown-item-padding-x);
      text-align: left;
      width: 100%;
      justify-content: flex-start !important;
      transition: $transition;
      &:hover,
      &:focus {
        color: var(--bs-dropdown-link-hover-color) !important;
        background-color: var(--bs-dropdown-link-hover-bg);
      }
      &:active {
        color: var(--bs-dropdown-link-active-color) !important;
        background-color: var(--bs-dropdown-link-active-bg);
      }
    }
  }
}

/* Box */
.list-wrapping {
  background: $glass;
  @include blurShadow();
  border-radius: $radius;
  --padX: 1.3em;
  --padY: 1em;
  @include media-breakpoint-down(xl) {
    background: transparent;
    border-radius: 0px;
    box-shadow: none;
    --padX: 0px;
  }
  &-top {
    background-color: rgba($pale-blue, 0.2);
    border-radius: $radius $radius 0px 0px;
    padding: var(--padY) var(--padX);
    @include media-breakpoint-down(xl) {
      display: none;
    }
    ul {
      @include list();
      @include flex();
      padding: 0.25em 1em;
      font-weight: 700;
      font-size: 1.2em;
      &.line-2 {
        .descr {
          flex: 1;
        }
        .price {
          width: 100px;
          text-align: right;
        }
        .btns {
          width: 60px;
        }
      }
      &.line-3 {
        display: grid;
        grid-template-columns: 140px 110px 1fr 200px 130px 80px;
        grid-template-rows: 1fr;
        grid-template-areas: "date id descr seller status price";
        @include media-breakpoint-down(xxl) {
          grid-template-columns: 83px 90px 1fr 200px 130px 80px;
          font-size: 1em;
        }
        .date {
          grid-area: date;
        }
        .id {
          grid-area: id;
        }
        .descr {
          grid-area: descr;
        }
        .seller {
          grid-area: seller;
        }
        .status {
          grid-area: status;
        }
        .price {
          grid-area: price;
        }
      }
      &.line-operation {
        display: grid;
        grid-template-columns: 21% 21% 21% 21% 16%;
        grid-template-rows: 1fr;
        grid-template-areas: "date id type stat sum";
        .date {
          grid-area: date;
        }
        .id {
          grid-area: id;
        }
        .type {
          grid-area: type;
        }
        .stat {
          grid-area: stat;
        }
        .sum {
          grid-area: sum;
        }
      }
      &.line-appeal {
        display: grid;
        grid-template-columns: 35% 13% 22% 20% 10%;
        grid-template-rows: 1fr;
        grid-template-areas: "subject id status date btns";
        .subject {
          grid-area: subject;
        }
        .id {
          grid-area: id;
        }
        .status {
          grid-area: status;
        }
        .date {
          grid-area: date;
        }
        .btns {
          grid-area: btns;
        }
      }
    }
  }
  &-main {
    padding: 0px;
    ul {
      @include list();
      li {
        border-bottom: 1px solid rgba($color: $pale-blue, $alpha: 0.2);
        @include media-breakpoint-down(xl) {
          border-bottom: none;
        }
      }
    }
  }
  &-bottom {
    padding: var(--padX) var(--padX);
    @include media-breakpoint-down(xl) {
      padding: var(--padY) 0 0;
    }
  }
}

body {
  margin: 0;
  font-family: $main-font, "Arial", "Tahoma", "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 400;
  color: $black;
  overflow-x: hidden;
  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}

main {
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  padding: 2em 0;
  @include media-breakpoint-down(lg) {
    min-height: calc(100vh - var(--f-height));
    padding-bottom: var(--f-height);
  }
  @include media-breakpoint-down(sm) {
    padding: 1em 0;
  }
}

header {
  background-color: $white;
  position: relative;
  z-index: 1042;
  height: var(--h-height);
  @include media-breakpoint-down(lg) {
    display: none;
  }
  input {
    border-color: $pale-blue;
    @include placeholder($pale-blue);
  }
  .profile-link {
    @include flex();
    img {
      @include rounedImg(30px);
      margin-right: 0.5em;
    }
  }
}

/* Slider */
.main-slider {
  @include media-breakpoint-down(lg) {
    overflow: hidden;
    position: relative;
    padding-bottom: 2em;
  }
  @include media-breakpoint-down(sm) {
    padding-bottom: 1.5em;
  }
  &-box {
    @include flex(row, space-between, flex-start, nowrap);
    @include media-breakpoint-down(lg) {
      width: 300%;
      transition: 400ms ease-in-out;
      &.pos-1 {
        transform: translateX(0%);
      }
      &.pos-2 {
        transform: translateX(calc(100% / 3 * -1));
      }
      &.pos-3 {
        transform: translateX(calc(100% / 3 * 2 * -1));
      }
    }
  }
  &-item {
    --w: 15.5%;
    width: var(--w);
    position: relative;
    padding-top: 3.5em;
    transition: 400ms ease-in-out;
    @include media-breakpoint-down(xl) {
      --w: 16.5%;
      padding-top: 2.5em;
    }
    @include media-breakpoint-down(lg) {
      --w: 100%;
      padding-top: 0em;
    }
    .svg-mini {
      position: relative;
      z-index: 1;
      width: 112%;
      height: 372px;
      @include media-breakpoint-down(xxl) {
        height: 320px;
      }
      @include media-breakpoint-down(xl) {
        height: 284px;
      }
    }
    .svg-mobile {
      width: 100%;
      height: 552px;
      @include media-breakpoint-down(md) {
        height: 410px;
      }
      @include media-breakpoint-down(sm) {
        height: 73vw;
      }
    }
    .title-full {
      position: absolute;
      z-index: 10;
      top: 0%;
      left: 16%;
      width: 84%;
      overflow: hidden;
      opacity: 0;
      transition: 400ms linear 0ms;
      @include media-breakpoint-down(lg) {
        top: 5%;
        left: 30%;
        width: 70%;
      }
      @include media-breakpoint-down(md) {
        top: 3%;
      }
      h2 {
        line-height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        @include media-breakpoint-down(xl) {
          font-size: 2.5em;
        }
        @include media-breakpoint-down(lg) {
          font-size: 3em;
        }
        @include media-breakpoint-down(md) {
          font-size: 2.5em;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2.3em;
        }
      }
    }
    .title-mini {
      margin-top: 10px;
      width: 100%;
      overflow: hidden;
      opacity: 1;
      transition: 400ms linear 400ms;
      @include media-breakpoint-down(xl) {
        margin-top: 0px;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
      h2 {
        line-height: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        font-size: 2.5em;
        @include media-breakpoint-down(xxl) {
          font-size: 2em;
        }
      }
    }
    .lots {
      width: fit-content;
      height: fit-content;
      position: absolute;
      z-index: 10;
      bottom: 9%;
      right: -6%;
      @include flex(column, center, center, nowrap);
      color: $white;
      font-size: 0.75em;
      @include media-breakpoint-down(xxl) {
        font-size: 0.6em;
      }
      @include media-breakpoint-down(xl) {
        font-size: 0.55em;
      }
      .num {
        font-size: 1.1em;
        line-height: 140%;
        font-weight: 600;
        @include media-breakpoint-down(xxl) {
          font-size: 1.25em;
        }
      }
    }
    .link {
      position: absolute;
      z-index: 10;
      bottom: 2em;
      right: 2em;
      opacity: 0;
      color: $white;
      text-decoration: none;
      @include media-breakpoint-down(xl) {
        bottom: 1em;
        right: 1em;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
    &-active {
      @extend .main-slider-item;
      --w: 60.6%;
      @include media-breakpoint-down(xxl) {
        --w: 61%;
      }
      @include media-breakpoint-down(xl) {
        --w: 62%;
      }
      @include media-breakpoint-down(lg) {
        --w: 100%;
      }
      .svg-full {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 458px;
        margin-top: -3em;
        @include media-breakpoint-down(xxl) {
          height: 400px;
          margin-top: -2.6em;
        }
        @include media-breakpoint-down(xl) {
          height: 340px;
          margin-top: -2em;
        }
      }
      .title-full {
        opacity: 1;
        transition: 400ms linear 400ms;
      }
      .title-mini {
        height: 0px;
        opacity: 0;
        transition: 400ms linear 0ms;
      }
      .lots {
        top: -1.85em;
        left: 6.25%;
        bottom: unset;
        right: unset;
        font-size: 0.9em;
        @include media-breakpoint-down(xxl) {
          font-size: 0.8em;
        }
        @include media-breakpoint-down(xl) {
          font-size: 0.7em;
          top: -1.65em;
        }
        @include media-breakpoint-down(lg) {
          font-size: 1.3em;
          top: 7%;
          left: 12.5%;
        }
        @include media-breakpoint-down(md) {
          font-size: 1em;
        }
        @include media-breakpoint-down(sm) {
          font-size: 3vw;
          left: 12%;
        }
        .num {
          line-height: 120%;
          font-size: 1.6em;
          font-weight: 400;
        }
      }
      .link {
        opacity: 1;
        transition: 200ms ease-in-out 800ms;
      }
    }
  }
  &-prev,
  &-next {
    display: flex;
    position: absolute;
    top: 67.5%;
    font-size: 2em;
    @include media-breakpoint-down(md) {
      top: 66.5%;
      font-size: 1.5em;
    }
    @include media-breakpoint-down(sm) {
      font-size: 1.25em;
    }
  }
  &-prev {
    left: 0px;
  }
  &-next {
    right: 0px;
  }
  &-indicators {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    @include list();
    @include flex();
    li {
      flex-grow: 1;
      height: 0.6em;
      background-color: rgba($color: $black, $alpha: 0.15);
      border-radius: 0.3em;
      transition: $transition;
      @include media-breakpoint-down(sm) {
        height: 0.4em;
      }
      &:not(:first-child) {
        margin-left: 2em;
      }
      &.active {
        background-color: rgba($color: $black, $alpha: 1);
      }
    }
  }
}

/* Catalog */
.sec-catalog {
  background: url(imgs/bg/gradient.jpg) no-repeat;
  background-size: 100% 100%;
  .container {
    position: relative;
    @include media-breakpoint-down(lg) {
      @include flex(row, flex-start, stretch, nowrap);
    }
  }
  &-nav {
    position: absolute;
    z-index: 10;
    top: 0%;
    right: calc(100% + 12px);
    width: 60px;
    height: 100%;
    @include media-breakpoint-down(lg) {
      position: relative;
      top: unset;
      right: unset;
      height: auto;
      margin-left: 1em;
    }
    @include media-breakpoint-down(sm) {
      width: 40px;
    }
    .wrap {
      background: $glass;
      background-color: rgba(255, 255, 255, 0.7);
      @include blurShadow();
      border-radius: $radius;
      position: sticky;
      top: 0.5em;
      left: 0;
      width: 100%;
      height: fit-content;
      max-height: calc(100vh - 2em);
      padding: 0.5em;
      transition: $transition;
      @include media-breakpoint-down(lg) {
        float: right;
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
      form {
        position: relative;
        border: 1px solid transparent;
        border-radius: $radius;
        margin-bottom: 0.25em;
        @include flex();
        transition: $transition;
        button {
          @include absBG(2);
          width: 3em;
          color: $pale-blue;
          svg {
            font-size: 1.6em;
          }
        }
        input {
          width: 100%;
          opacity: 0;
          position: relative;
          z-index: 1;
          @include placeholder($pale-blue);
          padding: 0.6em 0 0.6em 3em;
        }
      }
      ul {
        @include list();
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(9, 1fr);
        grid-auto-flow: column;
        li {
          grid-column: span 1;
          grid-row: span 1;
          font-size: 1.3em;
          text-align: center;
          padding: 0.4em;
          a {
            @include flex();
            color: $black;
            position: relative;
            background-color: transparent;
            transition: $transition;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            white-space: nowrap;
            &:hover {
              background-color: rgba($color: $pale-blue, $alpha: 0.25);
            }
            &.ss-active {
              background-color: rgba($color: $pale-blue, $alpha: 0.5);
            }
          }
        }
      }
      #cut {
        font-size: 1.3em;
        position: absolute;
        top: 0px;
        right: -0.5em;
        width: 1em;
        height: 1em;
        transform: translateY(0.25em);
        @include flex();
        border-radius: 50%;
        overflow: hidden;
        @include media-breakpoint-down(lg) {
          right: unset;
          left: -0.5em;
        }
        img {
          width: 100%;
          height: 100%;
          transition: $transition;
          @include media-breakpoint-down(lg) {
            transform: rotate(-180deg);
          }
        }
        &.opened {
          img {
            transform: rotate(-180deg);
            @include media-breakpoint-down(lg) {
              transform: rotate(0deg);
            }
          }
        }
      }
      &.full {
        width: 180px;
        @include media-breakpoint-down(sm) {
          width: 130px;
        }
        form {
          // border: 1px solid $pale-blue;
          input {
            opacity: 1;
          }
        }
      }
    }
  }
  &-box {
    flex: 1;
  }
  &-part {
    padding: 1em 1.5em;
    background: $glass;
    @include blurShadow();
    border-radius: 10px;
    &:not(:last-child) {
      margin-bottom: 3.33em;
    }
    @include media-breakpoint-down(sm) {
      padding: 1em;
    }
  }
  .letter {
    font-size: 3.33em;
    font-weight: 700;
    color: rgba(67, 185, 198, 0.26);
    margin-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      line-height: 100%;
    }
  }
}
.game-card {
  @include flex(row, flex-start, flex-start, nowrap);
  .img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 0.5em;
  }
  h4 {
    padding: 0 0 0.6em 0.65em;
  }
  .categories {
    @include list();
    @include flex(row, flex-start, center, wrap);
    padding: 0.85em 0.5em 0.5em;
    @include media-breakpoint-down(xxl) {
      padding: 0.75em 0em 0;
    }
    li {
      margin-bottom: 0.67em;
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
  &-mini {
    @include flex(row, flex-start, center, nowrap);
    background-color: $white;
    border-radius: $radius;
    @include lightShadow();
    padding: 1.6em 1.2em;
    @include media-breakpoint-down(sm) {
      padding: 1em 0.75em;
    }
    .img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 0.5em;
      @include media-breakpoint-down(sm) {
        width: 30px;
        height: 30px;
      }
    }
    h6 {
      font-size: 1.1em;
    }
  }
}
.servers {
  position: relative;
  @include list();
  @include flex(row, flex-start, center, nowrap);
  background: rgba($color: $green, $alpha: 0.8);
  @include btnStyle();
  width: fit-content;
  border-radius: $radius;
  padding: 0 0.5em;
  li {
    position: relative;
    color: $white;
    width: fit-content;
    text-transform: uppercase;
    padding: 0.6em 0.5em;
    transition: $transition;
    cursor: pointer;
    &.indicator {
      transform: translateY(-50%) translateX(-50%);
      padding: 0px;
      position: absolute;
      left: 0.5em;
      top: -1px;
      width: 20px;
      height: 14px;
      background: url(imgs/Subtract.svg) no-repeat center bottom;
      background-size: 100% 50%;
      @include flex();
      img {
        width: 8px;
        height: 8px;
        filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
      }
    }
    &.active {
      color: $dark-green;
    }
  }
}
.sec-promo {
  position: relative;
  @include bgTextureGradient();
  @include blurShadow();
  border-radius: $radius;
  padding: 0 10% 0 4em;
  color: $white;
  @include flex(row, space-between, center, nowrap);
  margin-top: 10%;
  @include media-breakpoint-down(xxl) {
    padding: 0 3em;
  }
  @include media-breakpoint-down(xl) {
    padding: 0 2em;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 15%;
  }
  @include media-breakpoint-down(sm) {
    padding: 1em;
    height: 85px;
  }
  img {
    width: 25%;
    margin-top: -11%;
    @include media-breakpoint-down(xl) {
      width: 22%;
      margin-top: -7%;
    }
    @include media-breakpoint-down(lg) {
      width: 50%;
      margin-top: -15%;
    }
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 55%;
    }
  }
  .text {
    position: relative;
    z-index: 10;
  }
}
.page-game {
  background: url(imgs/bg/gradient.jpg) no-repeat center bottom;
  background-size: 100% 65%;
  &-top {
    position: relative;
    .img {
      position: absolute;
      bottom: 0px;
      right: 0px;
      width: 45%;
      height: auto;
      z-index: 1;
      @include media-breakpoint-down(xxl) {
        width: 50%;
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      svg {
        width: 100%;
        height: auto;
      }
      &-lots {
        position: absolute;
        top: 14%;
        left: 10.7%;
        text-align: center;
        color: $white;
        .num {
          font-size: 1.6em;
        }
        @include media-breakpoint-down(sm) {
          font-size: 2.5vw;
        }
      }
    }
    .categories {
      position: unset;
      @include list();
      @include flex(row, flex-start, center, wrap);
      margin-top: 1em;
      margin-bottom: 2em;
      @include media-breakpoint-down(xxl) {
        margin-bottom: 1em;
      }
      @include media-breakpoint-down(xl) {
        position: relative;
        width: 60%;
        height: 460px;
        align-content: flex-end;
        justify-content: center;
        margin-bottom: 0em;
      }
      @include media-breakpoint-down(lg) {
        width: 80%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        height: 430px;
      }
      @include media-breakpoint-down(sm) {
        height: calc(85vw - 24px);
      }
      li {
        font-size: 1.2em;
        margin-right: 1.1em;
        margin-bottom: 1.1em;
        @include media-breakpoint-down(xl) {
          margin-bottom: 0.6em;
          position: relative;
          z-index: 10;
        }
        @include media-breakpoint-down(md) {
          font-size: 1em;
        }
        button,
        a {
          background-color: rgba($pale-blue, 0.2);
          padding: 0.55em;
          @include boxShadow();
          border-radius: $radius * 0.5;
          transition: $transition;
          &:hover,
          &:focus {
            background-color: rgba($pale-blue, 0.6);
          }
          &:active,
          &.active {
            background-color: rgba($pale-blue, 1);
            color: $white;
          }
          @include media-breakpoint-down(xl) {
            background-color: rgba($pale-blue, 0.85);
            &:hover,
            &:focus,
            &:active,
            &.active {
              background-color: $blue;
              color: $white;
            }
          }
        }
      }
    }
    .filter {
      position: relative;
      z-index: 10;
      fieldset {
        @include flex(row, flex-start, center, wrap);
        @include media-breakpoint-down(lg) {
          margin-top: 1em;
        }
        @include media-breakpoint-down(sm) {
          & > * {
            width: 100% !important;
          }
        }
      }
      input:not([type="checkbox"]) {
        border-color: $pale-blue;
        @include placeholder($pale-blue);
      }
      input[type="search"] {
        width: 340px;
        @include media-breakpoint-down(xxl) {
          width: 270px;
        }
        @include media-breakpoint-down(md) {
          width: 200px;
        }
      }
      input[type="number"] {
        width: 70px;
        padding: 0.7em 0.75em;
        text-align: center;
      }
      @include media-breakpoint-down(xl) {
        background: rgba($white, $alpha: 0.75);
        padding: 1em;
        border-radius: $radius;
        @include shadow();
      }
    }
  }
  &-offers {
    background: $glass;
    @include blurShadow();
    border-radius: $radius;
    --pad: 15px 20px;
    --serv: 220px;
    --seller: 220px;
    --availability: 165px;
    --price: 120px;
    @include media-breakpoint-down(xl) {
      background: transparent;
      border-radius: 0px;
      box-shadow: none;
      --pad: 0px 1em;
    }
    @include media-breakpoint-down(md) {
      --pad: 0px 0.5em;
      --price: 90px;
    }
    .top {
      background-color: rgba($pale-blue, 0.2);
      border-radius: $radius $radius 0px 0px;
      padding: 0 2em;
      display: grid;
      grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(
          --price
        );
      grid-template-rows: 1fr;
      grid-template-areas: "serv descr seller availability price";
      @include media-breakpoint-down(xl) {
        display: none;
      }
      div {
        font-size: 1.2em;
        font-weight: 600;
        padding: var(--pad);
        &.serv {
          grid-area: serv;
        }
        &.descr {
          grid-area: descr;
        }
        &.seller {
          grid-area: seller;
        }
        &.availability {
          grid-area: availability;
          text-align: center;
        }
        &.price {
          grid-area: price;
          text-align: center;
        }
      }
    }
    ul {
      @include list();
      padding: 2em;
      @include media-breakpoint-down(xl) {
        padding: 0px;
      }
    }
  }
}
.offer-line {
  display: grid;
  grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(
      --price
    );
  grid-template-rows: 1fr;
  grid-template-areas: "serv descr seller availability price";
  background-color: $white;
  @include boxShadow();
  border-radius: $radius;
  @include media-breakpoint-down(xl) {
    grid-template-columns: auto var(--price);
    grid-template-rows: 2em auto 3em;
    grid-template-areas:
      "serv price"
      "descr descr"
      "seller availability";
    gap: 10px 0px;
    padding: 1em 0;
  }
  @include media-breakpoint-down(md) {
    padding: 0.5em 0;
  }
  & > div {
    grid-column: span 1;
    grid-row: span 1;
    padding: var(--pad);
  }
  .serv {
    grid-area: serv;
    @include media-breakpoint-down(xl) {
      color: $blue;
      @include flex(row, flex-start, center, nowrap);
    }
  }
  .descr {
    grid-area: descr;
    @include media-breakpoint-down(xl) {
      @include clamp(3, 1.2em, true);
    }
  }
  .seller {
    grid-area: seller;
    @include flex(row, flex-start);
    @include media-breakpoint-between(sm, xl) {
      font-size: 0.8em;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 0.75em;
      @include media-breakpoint-down(xl) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .availability {
    grid-area: availability;
    text-align: center;
    @include media-breakpoint-down(xl) {
      @include flex(row, flex-end, center, nowrap);
      text-align: right;
    }
  }
  .price {
    grid-area: price;
    text-align: center;
    @include media-breakpoint-down(xl) {
      @include flex(row, flex-end, center, nowrap);
      text-align: right;
      font-weight: 600;
    }
  }
}

/* Trader page */
.trader-line {
  display: grid;
  grid-template-columns: 160px auto 120px;
  grid-template-rows: 1fr;
  grid-template-areas: "serv descr price";
  background-color: $white;
  @include boxShadow();
  border-radius: $radius;
  border-bottom: none;
  // @include media-breakpoint-down(xl) {
  //   grid-template-columns:auto var(--price);
  //   grid-template-rows: 2em auto 3em;
  //   grid-template-areas:
  //   "serv price"
  //   "descr descr"
  //   "seller availability";
  //   gap: 10px 0px;
  //   padding: 1em 0;
  // }
  & > div {
    grid-column: span 1;
    grid-row: span 1;
    padding: 1em;
  }
  .serv {
    grid-area: serv;
    @include media-breakpoint-down(xl) {
      color: $blue;
      @include flex(row, flex-start, center, nowrap);
    }
  }
  .descr {
    grid-area: descr;
    @include media-breakpoint-down(xl) {
      @include clamp(3, 1.2em, true);
    }
  }
  .price {
    grid-area: price;
    text-align: center;
    @include media-breakpoint-down(xl) {
      @include flex(row, flex-end, center, nowrap);
      text-align: right;
      font-weight: 600;
    }
  }
}

/* Lot page */
.lot-page {
  .specifications {
    @include list();
    font-size: 0.9em;
    li {
      @include flex(row, space-between, center, nowrap);
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0.2em;
        left: 0em;
        width: 100%;
        height: 1px;
        background-color: rgba($color: $pale-blue, $alpha: 0.25);
      }
      &:not(:first-child) {
        margin-top: 0.5em;
      }
      span {
        position: relative;
        z-index: 2;
        background-color: $white;
        padding: 0em 0.5em;
        line-height: normal;
        @include textEllipsis();
        &:first-child {
          color: $gray;
        }
        &:last-child {
          color: $blue;
          width: 45%;
          @include media-breakpoint-down(sm) {
            width: fit-content;
            margin-left: 1em;
          }
        }
      }
    }
  }
  &-box {
    border: 1px solid rgba($color: $pale-blue, $alpha: 0.25);
    border-radius: $radius;
    hr {
      background-color: rgba($color: $pale-blue, $alpha: 0.25);
      width: 100%;
      height: 1px;
      border: none;
      margin: 0px;
      opacity: 1;
    }
    .seller {
      @include flex(row, flex-start, center, nowrap);
      img {
        @include roundedImg(70px, 50%, cover);
        margin-right: 1em;
      }
      .rating {
        font-size: 1.2em;
        font-weight: 700;
        @include flex();
        svg {
          color: $blue;
          font-size: 1.1em;
          path {
            fill: $blue;
          }
        }
      }
    }
  }
  &-grid {
    display: grid;
    grid-template-columns: 120px 4fr 5fr;
    grid-template-rows: 35px 40px 30px 85px 70px;
    gap: 0px 0px;
    grid-template-areas:
      "game . date"
      "game info info"
      "game title title"
      "payment payment payment"
      "text text specifications";
    @include media-breakpoint-down(xl) {
      grid-template-columns: 120px 3fr 5fr;
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 120px auto;
      grid-template-rows: auto;
      grid-template-areas:
        "game date"
        "game info"
        "title title"
        "text text"
        "specifications specifications"
        "payment payment";
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 100px auto;
    }
    .game {
      grid-area: game;
      width: 100%;
      @include flex(column, flex-end, center, nowrap);
      @include media-breakpoint-down(md) {
        padding: 1em;
      }
      img {
        @include roundedImg(50px, 50%, cover);
      }
    }
    .date {
      grid-area: date;
      @include flex(row, flex-end, flex-end, nowrap);
      padding: 0px 1em;
      color: $light-gray;
      @include media-breakpoint-down(sm) {
        align-items: center;
      }
      time {
        font-size: 0.8em;
      }
    }
    .info {
      grid-area: info;
      @include flex(row, flex-start, center, nowrap);
      @include media-breakpoint-down(sm) {
        @include flex(column, space-evenly, stretch, nowrap);
      }
      .tag-gray {
        background-color: $extrapale-gray;
        padding: 0.25em 0.5em;
        border-radius: 0.5em;
      }
      .tag-green {
        background-color: $green;
        color: $white;
        padding: 0.25em 0.5em;
        border-radius: 0.5em;
      }
    }
    .title {
      grid-area: title;
      color: $blue;
      @include flex(row, flex-start, flex-end, nowrap);
      @include media-breakpoint-down(md) {
        padding: 1em;
      }
      p {
        @include media-breakpoint-up(md) {
          @include textEllipsis();
        }
      }
    }
    .payment {
      grid-area: payment;
      @include flex(row, flex-start, flex-end, nowrap);
      padding: 1em;
      border-bottom: 1px solid rgba($color: $pale-blue, $alpha: 0.25);
      @include media-breakpoint-down(md) {
        @include flex(column, center, stretch, nowrap);
        border-bottom: none;
        button {
          width: 100%;
          margin-top: 0.5em;
        }
      }
    }
    .text {
      grid-area: text;
      padding: 1em;
      @include flex(row, flex-start, center, nowrap);
      @include media-breakpoint-down(md) {
        padding-top: 0px;
        border-bottom: 1px solid rgba($color: $pale-blue, $alpha: 0.25);
      }
    }
    .specifications {
      grid-area: specifications;
      padding: 1em;
      @include media-breakpoint-down(xxl) {
        font-size: 0.8em;
      }
      @include media-breakpoint-down(md) {
        font-size: 1em;
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.9em;
      }
    }
  }
}
.review-card {
  border: 1px solid rgba($color: $pale-blue, $alpha: 0.25);
  background-color: rgba($color: $pale-blue, $alpha: 0.1);
  border-radius: $radius;
  padding: 0.75em;
  .user {
    @include flex(row, flex-start, center, nowrap);
    @include media-breakpoint-down(xxl) {
      flex: 1;
    }
    &-photo {
      @include roundedImg(30px, 50%, cover);
      border: none;
      margin-right: 0.5em;
    }
  }
  time {
    font-size: 0.8em;
    color: $pale-blue;
  }
  .rating {
    font-size: 1.2em;
    font-weight: 700;
    @include flex();
    svg {
      color: $blue;
      font-size: 1.1em;
      path {
        fill: $blue;
      }
    }
  }
}

/* Blog */
.sec-blog {
  .blog-list {
    @include list();
    &-item {
      position: relative;
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: $radius;
        margin-bottom: 1.3em;
        @include media-breakpoint-down(xxl) {
          height: 190px;
        }
      }
      h5 {
        @include clamp(3, 1.3em);
      }
      &-mobile {
        @include flex(row, flex-start, center, nowrap);
        position: relative;
        img {
          width: 100px;
          height: 80px;
          object-fit: cover;
          border-radius: $radius;
          margin-right: 1em;
        }
        h5 {
          flex: 1;
        }
      }
    }
  }
}
.cognition {
  flex: 1;
  @include flex(row, flex-end, center, nowrap);
  background-color: $black;
  background-image: linear-gradient(to right, transparent, $black),
    url(imgs/bg/cogito.jpg);
  background-repeat: no-repeat;
  background-size: 50% 100%, 75% auto;
  background-position: 60% 50%, -10% 50%;
  color: $white;
  padding: 0em 2em;
  height: 60px;
  text-align: right;
  border-radius: $radius;
  transition: $transition;
  &:hover,
  &:focus,
  &:active {
    color: $light-blue;
  }
}
.page-blog {
  .article-mini {
    position: relative;
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: $radius $radius 0 0;
      @include media-breakpoint-down(xxl) {
        height: 220px;
      }
      @include media-breakpoint-down(xl) {
        height: 180px;
      }
      @include media-breakpoint-down(lg) {
        height: 200px;
      }
      @include media-breakpoint-down(md) {
        height: 155px;
      }
      @include media-breakpoint-down(sm) {
        height: 33vw;
      }
    }
    figcaption {
      background-color: $white;
      border-radius: 0 0 $radius $radius;
      @include lightShadow();
      padding: 1.5em 1.5em 0.6em;
      @include media-breakpoint-down(xl) {
        padding: 1em 1.25em 0.6em;
      }
      @include media-breakpoint-down(sm) {
        padding: 0.6em 1em;
      }
      h5 {
        font-weight: 600;
        color: $total-black;
        @include clamp(2, 1.2em, true);
        a {
          line-height: 1.2em;
        }
      }
      time {
        display: block;
        text-align: right;
        margin-top: 0.5em;
        color: $pale-blue;
        font-weight: 500;
        font-size: 1.2em;
        @include media-breakpoint-down(lg) {
          font-size: 1em;
        }
      }
    }
  }
  article {
    p {
      color: $total-black;
      margin-bottom: 2em;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1em;
      }
    }
    img {
      max-width: 100%;
      border-radius: $radius;
      margin-bottom: 2em;
      @include media-breakpoint-down(sm) {
        margin-bottom: 1em;
      }
    }
  }
}

/* bottom background */
.sec-bottom {
  background: url(imgs/bg/gradient.jpg) no-repeat center;
  background-size: 100% 100%;
}

/* Chat */
.sec-chat {
  h2 {
    margin-left: 3.5em;
    margin-bottom: 0.1em;
  }
}
.sec-messages-chat {
  box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
}
.chat {
  position: relative;
  &-window {
    height: 698px;
    overflow: auto;
    padding: 1em 1.5em;
    @include media-breakpoint-down(lg) {
      padding: 1em 1.5em;
      height: 500px;
    }
    @include media-breakpoint-down(md) {
      padding: 1em;
    }
    @include media-breakpoint-down(sm) {
      padding: 1em 0.5em;
    }
    &-message {
      @include flex(row, flex-start, flex-start);
      &:not(:first-child) {
        margin-top: 1.5em;
      }
      img {
        width: 3.34em;
        height: 3.34em;
        border-radius: 50%;
        position: relative;
        z-index: 10;
        margin-right: 1em;
      }
      .text {
        flex: 1;
        @include flex(column, flex-start, flex-start, nowrap);
        .bubble {
          position: relative;
          width: fit-content;
          min-width: 50%;
          background: rgba($color: $pale-blue, $alpha: 0.2);
          border-radius: $radius;
          padding: 1em;
          font-weight: 400;
          font-size: 0.9em;
          text-align: left;
        }
      }
      &-mine {
        @extend .chat-window-message;
        img {
          margin-left: 1em;
          margin-right: 0px;
        }
        .text {
          align-items: flex-end;
          .bubble {
            background: rgba($color: $pale-green, $alpha: 0.2);
            text-align: right;
          }
        }
      }
    }
  }
  &-form {
    @include flex(row, flex-start, center, nowrap);
    border-top: 1px solid rgba($pale-blue, 0.3);
    padding: 0.5em;
    input {
      border: none;
      background-color: transparent;
      height: 100%;
    }
  }
}

/* Popular products */
.sec-popular {
  h2 {
    margin-bottom: 0.1em;
    padding: 0 0.35em;
    font-size: 2.85em;
    @include media-breakpoint-down(xl) {
      font-size: 2.65em;
    }
  }
  .offers-slider {
    height: 860px;
    padding: 0 1.5em 0 1em;
    margin-right: -0.5em;
    .swiper-scrollbar {
      right: 0.5em;
      background-color: #fff;
      box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8),
        1px 3px 20px rgba(100, 100, 100, 0.15);
      backdrop-filter: blur(4px);
      width: 5px;
      &-drag {
        background-color: $pale-blue;
      }
    }
    .swiper-slide {
      height: fit-content;
    }
    .offer-card {
      background: $glass;
      @include blurShadow();
      border-radius: $radius;
      padding: 1.6em 1.6em 0.7em 1.6em;
      &-top {
        @include flex(row, space-between, center, nowrap);
        h5 {
          font-weight: 700;
          color: $green;
          h5 {
            @include clamp(1, 1.4em, true);
          }
        }
      }
      &-main {
        margin-top: 1em;
        @include flex(row, space-between, flex-start, nowrap);
        h5 {
          @include clamp(2, 1.4em, true);
        }
      }
      &-text {
        margin-top: 1em;
        p {
          font-weight: 300;
          @include clamp(2, 1.4em);
        }
      }
      &-bottom {
        margin-top: 1em;
        @include flex(row, space-between, center, nowrap);
      }
    }
  }
}

/* Registration page */
.sec-registration {
  margin-top: 4em;
  @include media-breakpoint-down(lg) {
    margin-top: 0em;
  }
  // .title {
  //   border-radius: $radius;
  //   @include bgTextureGradient(linear-gradient(165deg, $black 0%, #4A83DC 55%, $total-black 100%));
  //   color: $white;
  //   height: 135px;
  //   padding: 0 3em;
  //   @include flex(row, flex-start, center, nowrap);
  //   position: relative;
  //   @include media-breakpoint-down(lg) {
  //     padding-right: 25%;
  //   }
  //   @include media-breakpoint-down(sm) {
  //     height: 100px;
  //     padding: 0 1em;
  //     padding-right: 1em;
  //   }
  //   h1 {
  //     line-height: 100%;
  //     position: relative;
  //     z-index: 20;
  //   }
  //   img {
  //     position: absolute;
  //     right: 20%;
  //     bottom: 0%;
  //     height: 160%;
  //     z-index: 10;
  //     @include media-breakpoint-down(xxl) {
  //       right: 10%;
  //     }
  //     @include media-breakpoint-down(lg) {
  //       right: 3%;
  //       height: unset;
  //       width: 22%;
  //     }
  //     @include media-breakpoint-down(md) {
  //       width: 28%;
  //     }
  //   }
  // }
  // .box {
  //   background: $glass;
  //   @include blurShadow();
  //   border-radius: $radius;
  //   padding: 2em;
  //   @include flex(row, space-between, flex-start, nowrap);
  //   @include media-breakpoint-down(lg) {
  //     flex-direction: column;
  //   }
  //   @include media-breakpoint-down(sm) {
  //     padding: 1.5em;
  //   }

  // }
  form {
    &.midi {
      width: 530px;
      @include media-breakpoint-down(xxl) {
        width: 480px;
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
    &.mini {
      width: 440px;
      @include media-breakpoint-down(xxl) {
        width: 330px;
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-top: 4em;
      }
    }
  }
}

/* Entering page */
.sec-login {
  .wrap {
    @include blurShadow();
    padding: 2em 2em 1.5em 2em;
    border-radius: $radius;
    height: 100%;
    @include media-breakpoint-down(sm) {
      padding: 1.5em 1.5em 1em 1.5em;
    }
  }
  .cover {
    border-radius: $radius;
    @include bgTextureGradient(
      linear-gradient(115deg, $black 0%, $blue 55%, $total-black 100%)
    );
    color: $white;
    height: 395px;
    padding: 0 5em;
    @include flex(row, flex-start, center, nowrap);
    position: relative;
    overflow: hidden;
    @include safariFix();
    @include media-breakpoint-down(xxl) {
      height: 412px;
      padding: 0 3em;
    }
    @include media-breakpoint-down(lg) {
      height: 150px;
      overflow: visible;
      margin-bottom: 2em;
    }
    @include media-breakpoint-down(sm) {
      height: 100px;
      padding: 0 1em;
      margin-bottom: 1em;
    }
    h2 {
      line-height: 100%;
    }
    img {
      position: absolute;
      right: 0%;
      bottom: 0%;
      height: 85%;
      z-index: 10;
      @include media-breakpoint-down(xxl) {
        height: 70%;
      }
      @include media-breakpoint-down(lg) {
        height: 110%;
        right: 15%;
      }
      @include media-breakpoint-down(md) {
        right: 5%;
      }
      @include media-breakpoint-down(sm) {
        height: 100%;
      }
    }
  }
}

/* Password restoration page */
.sec-password {
  .wrap {
    @include blurShadow();
    padding: 2em 2em 1.5em 2em;
    border-radius: $radius;
    @include media-breakpoint-down(sm) {
      padding: 1.5em 1.5em 1em 1.5em;
    }
  }
}

.sec-favorites {
  ul {
    @include list();
  }
  .fav-item {
    @include flex(row, flex-start, center, nowrap);
    background-color: $white;
    @include lightShadow();
    padding: 0.65em 1.3em;
    border-radius: $radius;
    @include media-breakpoint-down(xxl) {
      padding: 0.5em 1em;
    }
    img {
      @include rounedImg(50px);
      margin-right: 1em;
    }
    h5 {
      flex: 1;
      @include clamp(2, 1.2em, false);
    }
    button {
      @include flex();
      color: $gray;
      font-size: 1.35em;
      svg {
        stroke-width: 1px;
      }
    }
  }
}

/***********
ACCOUNT
***********/
.account {
  position: relative;
  overflow: hidden;
  .return-icon {
    display: flex;
    font-size: 2em;
    color: $blue;
  }
  @include media-breakpoint-down(lg) {
    h1 {
      color: $blue;
      font-size: 1.5em;
    }
  }
  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 1.25em;
    }
    .return-icon {
      font-size: 1.6em;
      color: $blue;
    }
  }
  .account-bg {
    @include absBG(1, 60%, auto);
  }
  .container {
    position: relative;
    z-index: 2;
  }
  &-nav {
    &-balance {
      background-color: $blue;
      color: $white;
      padding: 1em;
      border-radius: $radius;
      text-align: center;
      margin-bottom: 1em;
      div {
        @include flex();
        span {
          font-family: $title-font;
          font-size: 2em;
          margin-right: 0.34em;
          line-height: 90%;
        }
        svg {
          font-size: 1.5em;
          padding: 0.1em;
          border: 1px solid $white;
          border-radius: 50%;
        }
      }
    }
    ul {
      @include list();
      li {
        &:not(:first-child) {
          margin-top: 1em;
          @include media-breakpoint-down(lg) {
            margin-top: 0.5em;
          }
        }
        a {
          color: $gray;
          @include flex(row, flex-start, center, nowrap);
          padding: 0.67em 1em;
          border-radius: $radius;
          transition: $transition;
          @include media-breakpoint-down(lg) {
            font-size: 1.2em;
          }
          svg {
            font-size: 1.6em;
          }
          div {
            margin-left: 0.5em;
            flex: 1;
          }
          .badge {
            background-color: rgba($pale-blue, 0.2);
            padding: 0.34em;
            border-radius: $radius;
            color: $total-black;
          }
          &:hover,
          &:focus {
            color: $blue;
          }
          &.active {
            background-color: rgba($pale-blue, 0.2);
            color: $blue;
          }
        }
      }
    }
  }
  .sec-profile {
    position: relative;
    .share-btn {
      font-size: 1.5em;
      color: $dark-blue;
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 0em;
        right: 0em;
      }
    }
  }
  .post {
    display: block;
    background-color: rgba($pale-blue, 0.2);
    border-radius: $radius;
    color: $total-black;
    text-align: center;
    width: 124px;
    padding-bottom: 0.2em;
    max-width: 100%;
    @include media-breakpoint-down(xxl) {
      @include flex(row, flex-start, center, nowrap);
      width: 100%;
      text-align: left;
      padding: 0.3em 0.6em;
    }
    img {
      width: 110%;
      margin-top: -25%;
      @include media-breakpoint-down(xxl) {
        width: 3em;
        margin-top: unset;
        margin-right: 0.5em;
      }
    }
  }
  .btn-add-offer {
    background-color: rgba($color: $pale-blue, $alpha: 0.2);
    color: $pale-blue;
    @include flex();
    font-size: 6.5em;
    border-radius: 10px;
    @include lightShadow();
    transition: $transition;
    &:hover,
    &:focus,
    &:active {
      color: $blue;
    }
    @include media-breakpoint-down(md) {
      font-size: 4.5em;
    }
    @include media-breakpoint-down(sm) {
      font-size: 3em;
    }
  }
  .add-offer {
    padding: 3em 2em;
    border-radius: $radius;
    background: $glass;
    @include blurShadow();
    @include media-breakpoint-down(sm) {
      padding: 1.5em;
    }
  }
  .sec-feedback {
    .user {
      width: 50%;
      @include media-breakpoint-down(xl) {
        width: 75%;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      &-photo {
        width: 60px;
        height: 60px;
      }
      &-main {
        height: 72px;
        margin-left: -20px;
        padding-left: 30px;
        .title {
          @include clamp(1, 1em, true);
        }
      }
    }
    .box {
      background-color: $white;
      @include lightShadow();
      border-radius: $radius;
      padding: 0.8em 1.3em;
    }
    .info-list {
      @include list();
      @include flex(row, flex-start, center, wrap);
      li {
        margin-right: 1.5em;
        margin-bottom: 1em;
        border-bottom: none;
        @include media-breakpoint-down(sm) {
          margin-right: 1em;
          margin-bottom: 0.75em;
        }
      }
    }
  }
  .sec-finance {
    .balance {
      background-color: $blue;
      color: $white;
      padding: 1em 1.2em;
      border-radius: $radius;
      @include flex();
    }
  }
  .sec-messages {
    @include flex(row, flex-start, stretch, wrap);
    background-color: $white;
    border: 1px solid rgba($pale-blue, 0.3);
    box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
    backdrop-filter: blur(4px);
    border-radius: $radius;
    overflow: hidden;
    @include safariFix();
    &-list {
      width: 35%;
      border-right: 1px solid rgba($pale-blue, 0.3);
      @include media-breakpoint-down(xxl) {
        width: 40%;
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
        border-right: none;
      }
      form {
        border-bottom: 1px solid rgba($pale-blue, 0.3);
      }
      ul {
        margin-top: 1.5em;
        @include list();
      }
      .general-chat {
        @include flex(row, flex-start, center, nowrap);
        padding: 0.75em 1.3em;
        .count {
          background-color: $black;
          color: $white;
          @include rounedImg(4.8em);
          @include flex(column, center, center, nowrap);
          font-size: 10px;
          text-align: center;
          line-height: 100%;
        }
        h6 {
          margin-left: 0.5em;
          flex: 1;
          text-align: left;
        }
      }
    }
    &-chat {
      width: 65%;
      position: relative;
      @include media-breakpoint-down(xxl) {
        width: 60%;
      }
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      .chat-window {
        height: 600px;
        @include media-breakpoint-down(xxl) {
          padding: 1.5em;
        }
        @include media-breakpoint-down(ыь) {
          height: 400px;
        }
      }
      .chat-form {
        @include media-breakpoint-down(xxl) {
          padding: 0 1.5em;
        }
      }
    }
  }
  .sec-callback {
    .box {
      background: $glass;
      border-radius: $radius;
      @include blurShadow();
      padding: 3em 2em;
      @include media-breakpoint-down(sm) {
        padding: 1.5em;
      }
    }
  }
  .sec-appeal {
    .top {
      background-color: rgba($pale-blue, 0.2);
      border-radius: $radius $radius 0 0;
      @include flex(row, space-between, center, nowrap);
      padding: 1.3em 2em;
      @include media-breakpoint-down(sm) {
        padding: 1em 1.5em;
      }
    }
    .box {
      background: rgba($white, 0.3);
      border-radius: 0 0 $radius $radius;
      @include blurShadow();
      padding: 1.5em 2em;
      .chat-window {
        height: 550px;
      }
    }
  }
  .rouble {
    font-size: 1em;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 1px solid #fff;
    @include flex();
  }
}
.user {
  position: relative;
  @include flex(row, flex-start, center, nowrap);
  @include lightShadow();
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
  &-photo {
    position: relative;
    z-index: 2;
    width: 110px;
    height: 110px;
    border-radius: 100%;
    overflow: hidden;
    @include safariFix();
    border: 5px solid $white;
    box-sizing: content-box;
    @include media-breakpoint-down(xxl) {
      width: 100px;
      height: 100px;
    }
    @include media-breakpoint-down(md) {
      width: 90px;
      height: 90px;
    }
    @include media-breakpoint-down(sm) {
      width: 70px;
      height: 70px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 0;
    }
    &::after {
      content: "";
      @include absBG(1, 50%, 50%);
      background: linear-gradient(to bottom, transparent, $total-black 50%);
      background-size: cover;
    }
    label {
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      bottom: 0.25em;
      color: $white;
      font-size: 1.34em;
      transition: $transition;
      &:hover {
        color: $green !important;
      }
    }
  }
  &-main {
    position: relative;
    z-index: 1;
    height: 144px;
    flex: 1;
    position: relative;
    margin-left: -60px;
    padding-left: 72px;
    @include flex(column, center, flex-start, nowrap);
    @include media-breakpoint-up(md) {
      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 70%;
        background-color: #ebf3fc;
        border-radius: $radius 0 0 $radius;
      }
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 50%;
        background-color: #ebf3fc;
        transform: skewX(-25deg);
        border-radius: $radius;
      }
    }
    @include media-breakpoint-down(md) {
      background-color: rgba($pale-blue, 0.2);
      border-radius: $radius;
      padding-left: 65px;
      height: 120px;
    }
    @include media-breakpoint-down(sm) {
      height: 100px;
    }
    .title {
      font-family: $title-font;
      font-size: 2em;
      line-height: 100%;
      @include clamp(2, 1em);
    }
  }
  &-info {
    @include list();
    @include flex(column, flex-start, flex-end, nowrap);
    margin-left: -1em;
    width: 215px;
    @include media-breakpoint-down(xxl) {
      width: 200px;
    }
    @include media-breakpoint-down(md) {
      font-size: 0.9em;
      width: 100%;
      margin-left: 0em;
      margin-top: 1em;
      flex-direction: row;
      justify-content: center;
      gap: 1em;
    }
    li {
      position: relative;
      padding: 0.6em;
      height: 41px;
      color: $black;
      font-weight: 600;
      width: 100%;
      @include flex(row, space-between, center, nowrap);
      @include media-breakpoint-up(md) {
        &:first-child {
          width: 79%;
        }
        &:nth-child(2) {
          width: 89.5%;
        }
        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 50%;
          background-color: $white;
          transform: skewX(-25deg);
          border-radius: $radius;
        }
        &::after {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0px;
          right: 0px;
          height: 100%;
          width: 70%;
          background-color: $white;
          border-radius: 0 $radius $radius 0;
        }
      }
      @include media-breakpoint-down(md) {
        background-color: $white;
        border-radius: $radius;
        flex-direction: column;
        height: auto;
        width: 23%;
      }
      div {
        @include flex();
        @include media-breakpoint-down(md) {
          flex-direction: column;
        }
        svg {
          font-size: 1.6em;
          margin-right: 0.34em;
          @include media-breakpoint-down(md) {
            color: $blue;
          }
          &.svg {
            stroke-width: 1.3px;
          }
          &.path {
            path {
              stroke-width: 1.3px;
            }
          }
        }
      }
      &:not(:first-child) {
        margin-top: 11px;
      }
    }
  }
  .qr-code {
    display: block;
    width: 100px;
    align-self: flex-start;
    @include media-breakpoint-down(xl) {
      width: 80px;
    }
    @include media-breakpoint-down(md) {
      position: absolute;
      top: 1em;
      right: 1em;
      z-index: 10;
    }
    @include media-breakpoint-down(sm) {
      width: 50px;
    }
  }
}
.settings-line {
  padding: 1em;
  color: $total-black;
  display: grid;
  grid-template-columns: 150px 110px auto 140px 120px 60px;
  grid-template-rows: auto;
  grid-template-areas: "session system browser ip region btns";
  gap: 0.5em;
  // @include media-breakpoint-down(xl) {
  //   flex-direction: column;
  //   align-items: stretch;
  // }
  @include media-breakpoint-down(xl) {
    grid-template-columns: 1fr 1fr 80px;
    grid-template-areas:
      "session system btns"
      "browser browser browser"
      "ip ip region";
    border: 1px solid $pale-gray;
    border-radius: $radius;
  }
  @include media-breakpoint-down(md) {
    font-size: 0.8em;
    gap: 0.25em;
    grid-template-columns: 1fr 1fr 60px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 1em;
  }
  .session {
    grid-area: session;
  }
  .system {
    grid-area: system;
    text-align: left;
    @include media-breakpoint-down(xl) {
      text-align: right;
    }
  }
  .browser {
    grid-area: browser;
    text-align: center;
    @include media-breakpoint-down(xl) {
      text-align: left;
    }
  }
  .ip {
    grid-area: ip;
    text-align: center;
    @include media-breakpoint-down(xl) {
      text-align: left;
    }
  }
  .region {
    grid-area: region;
    text-align: center;
    @include media-breakpoint-down(xl) {
      text-align: right;
    }
  }
  .btns {
    grid-area: btns;
    @include flex(row, flex-end, center, nowrap);
    button {
      @include flex();
      color: $blue;
      font-size: 1.35em;
      transition: $transition;
      &:hover,
      &:focus {
        color: $light-blue;
      }
      &:active {
        color: $dark-blue;
      }
    }
  }
}
.offer-line-2 {
  @include flex(row, space-between, center, nowrap);
  padding: 1em;
  color: $total-black;
  @include media-breakpoint-down(xl) {
    flex-direction: column;
    align-items: stretch;
    border: 1px solid $pale-gray;
    border-radius: $radius;
  }
  .descr {
    flex: 1;
    @include clamp(2, 1.2em);
    @include media-breakpoint-down(xl) {
      margin-bottom: 1em;
      @include clamp(4, 1.2em);
    }
  }
  .price {
    width: 100px;
    text-align: right;
    @include media-breakpoint-down(xl) {
      flex: 1;
    }
  }
  .btns {
    width: 60px;
    @include flex(row, flex-end, center, nowrap);
    button {
      @include flex();
      color: $blue;
      font-size: 1.35em;
      transition: $transition;
      &:hover,
      &:focus {
        color: $light-blue;
      }
      &:active {
        color: $dark-blue;
      }
    }
  }
}
.offer-line-3 {
  padding: 1em;
  color: $total-black;
  display: grid;
  grid-template-columns: 145px 110px 1fr 200px 130px 80px;
  grid-template-rows: 1fr;
  grid-template-areas: "date id descr seller status price";
  @include media-breakpoint-down(xxl) {
    grid-template-columns: 85px 90px 1fr 200px 130px 80px;
    font-size: 13px;
  }
  @include media-breakpoint-down(xl) {
    border: 1px solid $pale-gray;
    border-radius: $radius;
    font-size: 14px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 0.5em;
    grid-template-areas:
      "descr descr"
      "id id"
      "status status"
      "seller date"
      "seller price";
  }
  @include media-breakpoint-down(md) {
    font-size: 13px;
  }
  & > div {
    @include flex(row, flex-start, center, nowrap);
  }
  .date {
    grid-area: date;
    @include media-breakpoint-down(xxl) {
      @include flex(column, center, flex-start, nowrap);
    }
    @include media-breakpoint-down(xl) {
      align-items: flex-end;
    }
  }
  .id {
    grid-area: id;
  }
  .descr {
    grid-area: descr;
  }
  .seller {
    grid-area: seller;
    @include flex(row, flex-start);
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 0.75em;
      @include media-breakpoint-down(xl) {
        width: 40px;
        height: 40px;
      }
    }
  }
  .status {
    grid-area: status;
  }
  .price {
    grid-area: price;
    @include media-breakpoint-down(xl) {
      justify-content: flex-end;
    }
  }
}
.feedback-line {
  position: relative;
  @include flex(row, flex-start, flex-start, nowrap);
  img {
    --w: 50px;
    @include rounedImg(var(--w));
    border: 5px solid #fdfdfd;
    box-sizing: content-box;
    position: relative;
    z-index: 2;
    margin-top: 0.25em;
  }
  &-main {
    position: relative;
    z-index: 1;
    flex: 1;
    margin-left: -40px;
  }
  &-top {
    @include flex(row, space-between, center, nowrap);
    padding: 0px 1em 0px 60px;
    margin-bottom: 0.5em;
    @include media-breakpoint-down(sm) {
      @include flex(column, center, flex-start, nowrap);
      padding: 0px 1em 0px 45px;
    }
  }
  &-text {
    background-color: $white;
    @include lightShadow();
    border-radius: $radius;
    padding: 1em 1em 1.3em 60px;
    font-weight: 300;
    @include media-breakpoint-down(sm) {
      padding: 1em 1em 1.3em 45px;
    }
  }
}
.purchase-line {
  @include flex(row, space-between, center, nowrap);
  gap: 2em;
  color: $total-black;
  background-color: $white;
  @include lightShadow();
  border-radius: $radius;
  padding: 0.6em 1em;
  @include media-breakpoint-down(xl) {
    @include flex(column, space-between, flex-start, nowrap);
    gap: 1em;
    padding: 1em 1.5em;
  }
  &-text {
    flex: 1;
  }
  &-user {
    @include flex(row, flex-start, center, nowrap);
    img {
      @include rounedImg(50px);
    }
    h6 {
      font-size: 1.067em;
      font-weight: 500;
      text-align: left;
    }
    button {
      display: flex;
      transition: $transition;
      &:hover,
      &:focus {
        transform: scale(1.3);
      }
    }
    @include media-breakpoint-down(xl) {
      width: 100%;
      div {
        flex: 1;
      }
    }
  }
}
.operation-line {
  padding: 1em;
  color: $total-black;
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
  grid-template-rows: 1fr;
  grid-template-areas: "date id type stat sum";
  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "date date date stat stat"
      "id type type sum sum";
    gap: 0.5em;
    font-size: 0.9em;
  }
  @include media-breakpoint-down(md) {
    gap: 1em 1em;
    font-size: 1em;
  }
  .date {
    grid-area: date;
  }
  .id {
    grid-area: id;
    @include media-breakpoint-down(xl) {
      font-size: 0.9em;
      color: $gray;
    }
  }
  .type {
    grid-area: type;
  }
  .stat {
    grid-area: stat;
    @include media-breakpoint-down(xl) {
      text-align: right;
    }
  }
  .sum {
    grid-area: sum;
    @include media-breakpoint-down(xl) {
      text-align: right;
    }
  }
}
.appeal-line {
  background-color: $white;
  border-radius: $radius;
  @include lightShadow();
  padding: 1em;
  color: $total-black;
  display: grid;
  grid-template-columns: 35% 13% 22% 20% 10%;
  grid-template-rows: 1fr;
  grid-template-areas: "subject id status time btns";
  @include media-breakpoint-down(xl) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas:
      "date id"
      "subject subject"
      "status btns";
    gap: 0.5em;
  }
  .subject {
    grid-area: subject;
  }
  .id {
    grid-area: id;
    @include media-breakpoint-down(xl) {
      text-align: right;
    }
  }
  .status {
    grid-area: status;
  }
  time {
    grid-area: time;
  }
  .btns {
    grid-area: btns;
    @include flex(row, flex-end, center, nowrap);
  }
}
.dialog-preview {
  @include flex(row, flex-start, center, nowrap);
  padding: 0.75em 1.3em;
  border-radius: $radius;
  position: relative;
  &.active {
    background-color: rgba($light-gray, 0.2);
  }
  img {
    @include rounedImg(3em);
    margin-right: 0.5em;
    position: relative;
    z-index: 1;
  }
  .indicator {
    position: absolute;
    bottom: 0.5em;
    left: 3.5em;
    width: 0.67em;
    height: 0.67em;
    border-radius: 50%;
    z-index: 10;
    &.green {
      background-color: $green;
    }
  }
  .text {
    flex: 1;
    h6 {
      @include clamp(1, 1.2em, true);
    }
    time {
      font-weight: 300;
      color: $gray;
      font-size: 0.7em;
    }
    p {
      @include clamp(1, 1.2em, true);
      font-weight: 300;
      color: $gray;
    }
  }
}
/***********
ACCOUNT END
***********/
.privacy-policy {
  h4,
  p {
    margin-bottom: 1em;
  }
  h4:not(:first-child) {
    margin-top: 2em;
  }
}
.rules {
  color: #000;
  hr {
    border: none;
    opacity: 1;
    height: 1px;
    background-color: rgba($pale-blue, 0.3);
    margin: 0.6em 0 1.2em;
  }
}
.faq {
  .box {
    background-color: $total-black;
    color: $white;
    border-radius: $radius;
    position: relative;
    padding: 3em 40% 3em 2em;
    img {
      position: absolute;
      bottom: 0px;
      right: 5%;
      width: 35%;
      height: 110%;
      object-fit: scale-down;
      object-position: center bottom;
      z-index: 0;
    }
  }
  ul {
    @include list();
    li {
      border-bottom: 1px solid rgba($color: $pale-blue, $alpha: 0.35);
      padding: 0.5em 0px;
      margin-bottom: 1em;
      a {
        color: $blue;
        font-weight: 600;
        transition: $transition;
        &:hover,
        &:focus,
        &:active {
          color: $dark-blue;
        }
      }
    }
  }
}

/* Footer */
footer {
  height: var(--f-height);
  background-color: $black;
  color: $white;
  @include media-breakpoint-down(lg) {
    background-color: transparent;
    color: $black;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 10000;
  }
  .dev-link {
    width: fit-content;
    @include flex(row, center, center, nowrap);
    font-size: 16px;
    svg {
      margin-left: 0.3em;
      font-size: 1.2em;
    }
  }
  .nav-mobile {
    @include flex();
    .logo {
      padding: 0px;
      img {
        width: 70px;
      }
    }
    ul {
      @include list();
      @include flex(row, space-evenly);
      flex: 1;
      background: linear-gradient(
          92.32deg,
          rgba(255, 255, 255, 0) 16.31%,
          rgba(245, 251, 255, 0.3) 97.01%
        ),
        rgba(255, 255, 255, 0.8);
      @include shadowMobile();
      margin-left: 1.7em;
      padding: 0.75em 1.5em;
      border-radius: $radius;
      @include media-breakpoint-down(md) {
        justify-content: space-around;
      }
      @include media-breakpoint-down(sm) {
        justify-content: space-between;
      }
      li {
        a,
        button {
          @include flex();
          font-size: 1.85em;
          color: $gray;
          transition: $transition;
          svg {
            path {
              stroke-width: 1px;
            }
          }
          &.active {
            color: $blue;
            svg {
              path {
                stroke-width: 1px;
              }
            }
          }
        }
      }
    }
  }
}

/* Modal */
.modal {
  &-content {
    background-color: $white;
    border: none;
    border-radius: $radius;
  }
  &-header {
    position: relative;
    height: 35px;
    padding: 0px;
    background-color: rgba($pale-blue, 0.2);
    border: none;
    .warning {
      position: absolute;
      right: 2em;
      bottom: 0.25em;
      @include media-breakpoint-down(lg) {
        width: 20%;
      }
    }
    .btn-close {
      background: url(imgs/close.svg) no-repeat center;
      background-size: contain;
      background-color: $white;
      width: 1.34em;
      height: 1.34em;
      padding: 0px;
      margin: 0px;
      border-radius: 50%;
      overflow: hidden;
      @include safariFix();
      transition: $transition;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      z-index: 1;
      &:hover,
      &:focus {
        background-color: $dark-blue;
      }
    }
  }
  &-body {
    padding: 1.34em 2.68em;
    @include media-breakpoint-down(sm) {
      padding: 1.34em;
    }
  }
}

.offcanvas {
  &-body {
    position: relative;
    ul {
      @include list();
      background-color: $glass;
      border-radius: $radius;
      padding: 0 1em;
      font-size: 1.2em;
      @include blurShadow();
      li {
        a {
          display: block;
          padding: 0.75em 0;
          color: $total-black;
        }
        &:not(:first-child) {
          border-top: 1px solid #f3f3f3;
        }
      }
    }
    .sec-promo {
      h1 {
        font-size: 2.2em;
      }
      img {
        width: 40%;
        margin-top: -10%;
      }
    }
    .dev-link {
      @include flex();
    }
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      font-size: 1.5em;
      @include flex();
      color: $gray;
      transition: $transition;
      padding: 0.5em;
      &:hover,
      &:focus,
      &:active {
        color: $black;
      }
    }
  }
  &-top {
    background-color: transparent;
    height: fit-content !important;
    .offcanvas-body {
      padding: calc(var(--h-height) + 1em) 1em 2em 1em;
      background-color: $white;
      border-radius: 0px 0px $radius $radius;
    }
  }
}

.fav-modal {
  z-index: 1041;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  top: 65px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 13px 20px;
  box-shadow: 0px 0px 2px #919eab1f, 0px 8px 24px -4px #919eab33 !important;
  border-radius: 12px;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.9em;
  position: relative;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification-info {
  color: #2f96b4;
}

.notification-success {
  color: #36b37e;
}

.notification-warning {
  color: #f89406;
}

.notification-error {
  color: #bd362f;
}
