@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color, inherit);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 1110px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1290px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1470px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 1110px;
  --bs-breakpoint-xl: 1290px;
  --bs-breakpoint-xxl: 1470px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1110px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1290px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1470px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1110px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1290px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1109.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1289.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1469.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1109.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1109.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1109.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1110px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1289.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1289.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1289.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1290px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1469.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1469.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1469.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1470px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1110px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1290px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1470px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-fit-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fit-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-fit-scale {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-fit-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.column-gap-2 {
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.column-gap-4 {
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.column-gap-5 {
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-sm-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-sm-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-sm-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-md-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-md-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-md-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-md-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-md-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1110px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-lg-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-lg-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-lg-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1290px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1470px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root {
  --h-height: 85px;
  --f-height: 80px;
}
@media (max-width: 767.98px) {
  :root {
    --h-height: 70px;
  }
}
@media (max-width: 575.98px) {
  :root {
    --h-height: 55px;
    --f-height: 64px;
  }
}

/* mixins */
/*****************
SCROLL STYLIZATION
******************/
html {
  scrollbar-color: #9dc4ef transparent;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 4px 15px rgba(100, 100, 100, 0.08);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
::-webkit-scrollbar:hover, ::-webkit-scrollbar:active {
  background: rgba(157, 196, 239, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: #9dc4ef;
  border-radius: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #66c6e4;
}
::-webkit-scrollbar-thumb:active {
  background-color: #4a83dc;
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

/* Typography */
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
label,
span,
a,
button,
form,
fieldset,
legend,
select,
input,
textarea,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl,
li,
address {
  line-height: 135%;
  margin-bottom: 0px;
}

h1,
.h1 {
  font-family: "Bebas";
  font-size: 3.6em;
  font-weight: normal;
  text-align: left;
  line-height: 120%;
  margin-bottom: 0.2em;
}
@media (max-width: 1289.98px) {
  h1,
  .h1 {
    font-size: 3.5em;
  }
}
@media (max-width: 1109.98px) {
  h1,
  .h1 {
    font-size: 3.2em;
  }
}
@media (max-width: 767.98px) {
  h1,
  .h1 {
    font-size: 2.75em;
  }
}
@media (max-width: 575.98px) {
  h1,
  .h1 {
    font-size: 2.3em;
  }
}

h2,
.h2 {
  font-family: "Bebas";
  font-size: 3em;
  font-weight: normal;
  text-align: left;
  margin-bottom: 0.67em;
}
@media (max-width: 767.98px) {
  h2,
  .h2 {
    font-size: 2.75em;
  }
}
@media (max-width: 575.98px) {
  h2,
  .h2 {
    font-size: 2.3em;
    margin-bottom: 0.5em;
  }
}

h3, .h3 {
  font-size: 1.6em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0.2em;
}
@media (max-width: 1109.98px) {
  h3, .h3 {
    font-size: 1.4em;
  }
}

h4, .h4 {
  font-size: 1.3em;
  font-weight: 600;
  text-align: left;
  margin-bottom: 0.2em;
}
@media (max-width: 1109.98px) {
  h4, .h4 {
    font-size: 1.2em;
  }
}
@media (max-width: 767.98px) {
  h4, .h4 {
    font-size: 1.1em;
  }
}

h5, .h5 {
  font-size: 1.2em;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0px;
}
@media (max-width: 1109.98px) {
  h5, .h5 {
    font-size: 1.1em;
  }
}
@media (max-width: 767.98px) {
  h5, .h5 {
    font-size: 1em;
  }
}

h6, .h6 {
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.topic {
  background-color: #4a83dc;
  color: #ffffff;
  padding: 1em 2em;
  border-radius: 0.67em;
}
.topic h1, .topic .h1,
.topic h2,
.topic .h2,
.topic a {
  line-height: 100%;
}

.fs-08 {
  font-size: 0.8em;
}
.fs-09 {
  font-size: 0.9em;
}
.fs-10 {
  font-size: 1em;
}
.fs-11 {
  font-size: 1.1em;
}
.fs-12 {
  font-size: 1.2em;
}
.fs-13 {
  font-size: 1.3em;
}
.fs-14 {
  font-size: 1.4em;
}
.fs-15 {
  font-size: 1.5em;
}
.fs-16 {
  font-size: 1.6em;
}
.fs-17 {
  font-size: 1.7em;
}
.fs-18 {
  font-size: 1.8em;
}
.fs-19 {
  font-size: 1.9em;
}
.fs-20 {
  font-size: 2em;
}
.fs-25 {
  font-size: 2.5em;
}
.fs-30 {
  font-size: 3em;
}
.fs-35 {
  font-size: 3.5em;
}
.fs-40 {
  font-size: 4em;
}

.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

.green {
  color: #48d868;
}

.white {
  color: #ffffff;
}

.light-gray {
  color: #c1c1c1;
}

.gray {
  color: #8f8f8f;
}

.pale-blue {
  color: #9dc4ef;
}

.blue {
  color: #4a83dc;
}

.dark-blue {
  color: #0d59ab;
}

.rose {
  color: #ff5630;
}

.black {
  color: #1b1b1c;
}

.total-black {
  color: #000;
}

.title-font {
  font-family: "Bebas";
}

.bg-blue {
  background-color: #4a83dc;
}

.ruble {
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.lh-n {
  line-height: normal;
}

/* Form elements */
input,
textarea {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #c1c1c1;
  border-radius: 0.67em;
  padding: 0.725em 1.65em;
  width: 100%;
  outline: none;
  display: block;
  transition: 300ms ease-in-out;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #c1c1c1;
  font-weight: 400;
}
input::placeholder,
textarea::placeholder {
  color: #c1c1c1;
  font-weight: 400;
}
input.p-blue,
textarea.p-blue {
  border: 1px solid #9dc4ef;
}
input.p-blue::-moz-placeholder, textarea.p-blue::-moz-placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
input.p-blue::placeholder,
textarea.p-blue::placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
input:hover, input:focus, input:active,
textarea:hover,
textarea:focus,
textarea:active {
  border: 1px solid #4a83dc;
}

input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid #48d868;
  border-radius: 4px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  position: relative;
}
input[type=checkbox]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 40%;
  border-color: transparent;
  border-width: 0px 0px 3px 3px;
  border-style: solid;
  transform: translate(-50%, -65%) rotate(-45deg);
  transition: border-color 300ms ease-in-out;
}
input[type=checkbox]:checked::before {
  border-color: #48d868;
}
input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid #48d868;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  transition: 300ms ease-in-out;
  position: relative;
}
input[type=radio]::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #48d868;
  transition: 300ms ease-in-out;
  opacity: 0;
}
input[type=radio]:checked::before {
  opacity: 1;
}
input.switch {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  width: 40px;
  height: 20px;
  border: none;
  border-radius: 10px;
  background-color: #c1c1c1;
}
input.switch::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 3px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #ffffff;
  transition: 300ms ease-in-out;
  opacity: 1;
}
input.switch:checked {
  background-color: #48d868;
}
input.switch:checked::before {
  left: calc(100% - 19px);
}
input[type=search] {
  padding-right: 2.5em;
  background: url(imgs/SearchIcon.svg) no-repeat calc(100% - 0.67em) 50% rgba(255, 255, 255, 0.85);
  background-size: 1.6em;
}
input.code {
  font-family: "Bebas";
  font-size: 2.3em;
  padding: 0px 10px;
  text-align: center;
  line-height: 120%;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
label span {
  flex: 1;
}

.input-rating input {
  display: none;
}
.input-rating .stars {
  display: flex;
  flex-direction: row-reverse;
  width: -moz-fit-content;
  width: fit-content;
}
.input-rating .stars button {
  display: flex;
  color: #4a83dc;
  font-size: 2.15em;
}
.input-rating .stars button:not(:last-child) {
  margin-left: 0.2em;
}
.input-rating .stars button svg path {
  stroke: #4a83dc;
  fill: #ffffff;
  transition: 300ms ease-in-out;
}
.input-rating .stars button:hover svg path, .input-rating .stars button.active svg path, .input-rating .stars button.active ~ button svg path, .input-rating .stars button:hover svg path, .input-rating .stars button:hover ~ button svg path {
  fill: #4a83dc;
}

.password {
  position: relative;
}
.password input {
  font-size: inherit;
  font-weight: inherit;
  position: relative;
  z-index: 1;
}
.password button {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 5;
  color: #8f8f8f;
  height: 100%;
  width: 2em;
  font-size: 1.6em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.password button svg path {
  stroke-width: 1.25px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  outline: none;
  cursor: pointer;
  min-width: 250px;
  border-radius: 0.67em;
  padding: 0.75em 1.65em;
  border: none;
  box-shadow: 0px 4px 12px rgba(100, 100, 100, 0.08);
  background: url(imgs/chevron-down.svg), linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  background-repeat: no-repeat;
  background-position: calc(100% - 0.67em) 50%, 50% 50%;
  background-size: 1.6em, cover;
  transition: 300ms ease-in-out;
}
select:hover, select:focus {
  background-color: rgba(157, 196, 239, 0.1);
}
@media (max-width: 767.98px) {
  select {
    min-width: 200px;
  }
}

.labeled-input {
  position: relative;
}
.labeled-input label {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 1em;
  transform: translateY(-60%);
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ffffff;
  color: #8f8f8f;
  padding: 0 0.67em;
}
.labeled-input input {
  position: relative;
  z-index: 1;
  border-radius: 0.67em;
}
.labeled-input input:hover + label, .labeled-input input:focus + label, .labeled-input input:active + label {
  color: #4a83dc;
}
.labeled-input select {
  border-radius: 0.67em;
  width: 100%;
  border: 1px solid #c1c1c1;
  box-shadow: none;
  background: url(imgs/chevron-down-gray.svg) transparent;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.67em) 50%;
  background-size: 1.6em;
  min-width: 150px;
}
.labeled-input select:hover, .labeled-input select:focus, .labeled-input select:active {
  background: url(imgs/chevron-down.svg) transparent;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.67em) 50%;
  background-size: 1.6em;
  border: 1px solid #4a83dc;
}
.labeled-input:hover label, .labeled-input:focus label, .labeled-input:active label {
  color: #4a83dc;
}

.files-label {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: 300ms ease-in-out;
}
.files-label:hover {
  cursor: pointer;
}
.files-label input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0px;
  left: 0px;
  padding: 0px;
}
.files-label .icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  border: 1px solid rgba(157, 196, 239, 0.35);
}
.files-label .icon svg {
  color: #4a83dc;
  font-size: 1.5em;
  stroke-width: 1.25px;
}
.files-label .ind {
  position: absolute;
  bottom: -0.25em;
  right: 0px;
  color: #4a83dc;
  font-weight: 600;
  line-height: 1em;
  font-size: 0.9em;
}
.files-label .ind:empty {
  display: none;
}
.files-list {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.files-list li img {
  width: 100px;
  height: 100px;
  border-radius: 0.67em;
  -o-object-fit: cover;
     object-fit: cover;
}

.hv-100 {
  min-height: calc(100vh - var(--h-height) - var(--f-height) - 4em);
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}
button[disabled]:not(.btn):not(.btn-6):not(.btn-success):not(.btn-5):not(.btn-4):not(.btn-dark):not(.btn-3):not(.btn-danger):not(.btn-2):not(.btn-light):not(.btn-1):not(.btn-primary) {
  opacity: 0.5 !important;
}

.btn, .btn-6, .btn-success, .btn-5, .btn-4, .btn-dark, .btn-3, .btn-danger, .btn-2, .btn-light, .btn-1, .btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.8em 1.6em;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.67em;
}
.btn:active, .btn-6:active, .btn-success:active, .btn-5:active, .btn-4:active, .btn-dark:active, .btn-3:active, .btn-danger:active, .btn-2:active, .btn-light:active, .btn-1:active, .btn-primary:active, .btn:hover, .btn-6:hover, .btn-success:hover, .btn-5:hover, .btn-4:hover, .btn-dark:hover, .btn-3:hover, .btn-danger:hover, .btn-2:hover, .btn-light:hover, .btn-1:hover, .btn-primary:hover {
  transition: 300ms ease-in-out;
}
.btn-1, .btn-primary {
  color: #ffffff !important;
  background-color: #4a83dc !important;
  background-image: linear-gradient(150deg, #4a83dc 25%, #3266b8 50%, #4a83dc 75%) !important;
  background-size: 200% 100% !important;
  background-position: left center !important;
}
.btn-1:hover, .btn-1:focus, .btn-primary:hover, .btn-primary:focus {
  color: #ffffff;
  background-position: right center;
}
.btn-1:active, .btn-1.active, .btn-primary:active, .btn-primary.active {
  color: #ffffff !important;
  background-position: center center;
}
.btn-1[disabled], .btn-primary[disabled] {
  background: #c1c1c1 !important;
}
.btn-2, .btn-light {
  background: #ffffff;
  color: #1b1b1c;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
}
.btn-2:hover, .btn-2:focus, .btn-2:active, .btn-2.active, .btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active {
  color: #1b1b1c;
  background: #ebf3fc;
}
.btn-2[disabled], .btn-light[disabled] {
  background: #c1c1c1 !important;
}
.btn-3, .btn-danger {
  background: #ff5630;
  color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
}
.btn-3:hover, .btn-3:focus, .btn-danger:hover, .btn-danger:focus {
  background: #ff5630;
  color: #ffffff;
}
.btn-3:active, .btn-3.active, .btn-danger:active, .btn-danger.active {
  background: #ff5630;
  color: #ffffff;
}
.btn-3[disabled], .btn-danger[disabled] {
  background: #c1c1c1 !important;
}
.btn-4, .btn-dark {
  background: #000;
  color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
}
.btn-4:hover, .btn-4:focus, .btn-dark:hover, .btn-dark:focus {
  color: #ffffff;
  background: #0d59ab;
}
.btn-4:active, .btn-4.active, .btn-dark:active, .btn-dark.active {
  color: #ffffff;
  background: #005a47;
}
.btn-4[disabled], .btn-dark[disabled] {
  background: #c1c1c1 !important;
}
.btn-5 {
  background: #4a83dc;
  color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
}
.btn-5:hover, .btn-5:focus {
  color: #ffffff;
  background: #0d59ab;
}
.btn-5:active, .btn-5.active {
  color: #ffffff;
  background: #005a47;
}
.btn-5[disabled] {
  background: #c1c1c1 !important;
}
.btn-6, .btn-success {
  background: #48d868;
  color: #ffffff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
}
.btn-6:hover, .btn-6:focus, .btn-success:hover, .btn-success:focus {
  color: #ffffff;
  background: #66c6e4;
}
.btn-6:active, .btn-6.active, .btn-success:active, .btn-success.active {
  color: #ffffff;
  background: #005a47;
}
.btn-6[disabled], .btn-success[disabled] {
  background: #c1c1c1 !important;
}
.btn[disabled], [disabled].btn-1, [disabled].btn-primary, [disabled].btn-2, [disabled].btn-light, [disabled].btn-3, [disabled].btn-danger, [disabled].btn-4, [disabled].btn-dark, [disabled].btn-5, [disabled].btn-6, [disabled].btn-success {
  background: #c1c1c1;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.input-file-simple {
  position: relative;
  color: #8f8f8f;
  transition: 300ms ease-in-out;
}
.input-file-simple:hover {
  cursor: pointer;
  color: #4a83dc;
}
.input-file-simple input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0px;
  left: 0px;
  padding: 0px;
}
.input-file-simple svg {
  color: currentColor;
  font-size: 1.25em;
}
.input-file-simple .ind {
  position: absolute;
  bottom: -0.75em;
  right: -0.75em;
  color: #ffffff;
  line-height: 1em;
  font-size: 0.75em;
  background: #4a83dc;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.input-file-simple .ind:empty {
  display: none;
}

.lang-switcher {
  position: relative;
}
.lang-switcher button {
  width: 100%;
  font-size: 1em;
  padding: 0.1em 0.2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  color: #8f8f8f;
}
.lang-switcher img {
  width: 1.6em;
  height: 1.6em;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 4px 15px rgba(100, 100, 100, 0.08);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  margin-right: 0.5em;
}
.lang-switcher ul {
  list-style: none;
  padding-left: 0px;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
}
.lang-switcher ul li {
  width: 100%;
  margin: 0px !important;
}

.star-rating {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  color: #4a83dc;
  font-size: 1.34em;
}
.star-rating svg:not(:first-child) {
  margin-left: 3px;
}
.star-rating svg.filled path {
  fill: #4a83dc;
}

.link {
  color: #9dc4ef;
  text-decoration: underline;
  transition: 300ms ease-in-out;
}
.link:hover, .link:focus, .link:active {
  color: #0d59ab;
  text-decoration: underline;
}
.link-2 {
  color: #4a83dc;
  text-decoration: none;
  transition: 300ms ease-in-out;
}
.link-2:hover, .link-2:focus, .link-2:active {
  color: #66c6e4;
  text-decoration: none;
}

.return-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 2em;
}

.return-title {
  display: none;
}
@media (max-width: 1109.98px) {
  .return-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin-bottom: 2em;
  }
}

.min-250 {
  min-width: 250px;
}

@media (max-width: 575.98px) {
  .w-xs-100 {
    width: 100%;
  }
}
.flex-1 {
  flex: 1;
}

.mb-6 {
  margin-bottom: 6em;
}
@media (max-width: 767.98px) {
  .mb-6 {
    margin-bottom: 5em;
  }
}
@media (max-width: 575.98px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

/* Breadcrumbs */
.breadcrumbs {
  margin-bottom: 2em;
  color: #c1c1c1;
}
@media (max-width: 1289.98px) {
  .breadcrumbs {
    margin-bottom: 1.5em;
  }
}
.breadcrumbs ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.breadcrumbs ul li a {
  font-size: 1.2em;
  font-weight: 500;
}
@media (max-width: 1109.98px) {
  .breadcrumbs ul li a {
    font-size: 1.1em;
  }
}
@media (max-width: 767.98px) {
  .breadcrumbs ul li a {
    font-size: 1em;
  }
}
.breadcrumbs ul li:not(:first-child) {
  margin-left: 1em;
  padding-left: 1.4em;
  position: relative;
}
.breadcrumbs ul li:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 0px;
  display: block;
  height: 100%;
  width: 8px;
  background: url(imgs/arrow-mini.svg) no-repeat center;
  background-size: contain;
}
@media (max-width: 1109.98px) {
  .breadcrumbs ul li:not(:first-child)::before {
    width: 7px;
  }
}
@media (max-width: 767.98px) {
  .breadcrumbs ul li:not(:first-child)::before {
    width: 6px;
  }
}

/* Pagination */
nav.pagination ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
nav.pagination ul li:not(:first-child) {
  margin-left: 1.3em;
}
nav.pagination ul li a {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: transparent;
  color: #4a83dc;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: 300ms ease-in-out;
}
nav.pagination ul li a svg {
  font-size: 1.2em;
}
nav.pagination ul li a:hover, nav.pagination ul li a:focus {
  background-color: rgba(157, 196, 239, 0.15);
}
nav.pagination ul li a:active, nav.pagination ul li a.active {
  background-color: rgba(157, 196, 239, 0.3);
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
}

.accordion {
  --bs-accordion-color: #1b1b1c;
  --bs-accordion-btn-color: #4a83dc;
  --bs-accordion-active-color: #4a83dc;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-active-bg: transparent;
  --bs-accordion-btn-padding-y: 0.5em;
  --bs-accordion-btn-padding-x: 0px;
  --bs-accordion-body-padding-x: 0px;
  --bs-accordion-border-color: rgba(157, 196, 239, 0.3);
  --bs-accordion-btn-icon-width: 2em;
  --bs-accordion-btn-icon: url(imgs/chevron-down-blue.svg);
  --bs-accordion-btn-active-icon: url(imgs/chevron-down-blue.svg);
}
.accordion-button {
  box-shadow: unset;
  font-weight: 600;
}

.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-menu {
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1em;
  --bs-dropdown-color: #1c1c1c;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #f4f6f8;
  --bs-dropdown-border-radius: 10px;
  --bs-dropdown-border-width: 0px;
  --bs-dropdown-inner-border-radius: 9px;
  --bs-dropdown-link-color: #1c1c1c;
  --bs-dropdown-link-hover-color: #1c1c1c;
  --bs-dropdown-link-hover-bg: #f4f6f8;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #4a83dc;
  --bs-dropdown-item-padding-x: 1em;
  --bs-dropdown-item-padding-y: 0.5em;
  --bs-dropdown-box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  box-shadow: var(--bs-dropdown-box-shadow);
  padding: 0px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
.dropdown-item {
  padding: 0px;
}
.dropdown-item a {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  text-align: left;
  width: 100%;
  justify-content: flex-start !important;
  transition: 300ms ease-in-out;
}
.dropdown-item a:hover, .dropdown-item a:focus {
  color: var(--bs-dropdown-link-hover-color) !important;
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item a:active {
  color: var(--bs-dropdown-link-active-color) !important;
  background-color: var(--bs-dropdown-link-active-bg);
}

/* Box */
.list-wrapping {
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
  --padX: 1.3em;
  --padY: 1em;
}
@media (max-width: 1289.98px) {
  .list-wrapping {
    background: transparent;
    border-radius: 0px;
    box-shadow: none;
    --padX: 0px;
  }
}
.list-wrapping-top {
  background-color: rgba(157, 196, 239, 0.2);
  border-radius: 0.67em 0.67em 0px 0px;
  padding: var(--padY) var(--padX);
}
@media (max-width: 1289.98px) {
  .list-wrapping-top {
    display: none;
  }
}
.list-wrapping-top ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.25em 1em;
  font-weight: 700;
  font-size: 1.2em;
}
.list-wrapping-top ul.line-2 .descr {
  flex: 1;
}
.list-wrapping-top ul.line-2 .price {
  width: 100px;
  text-align: right;
}
.list-wrapping-top ul.line-2 .btns {
  width: 60px;
}
.list-wrapping-top ul.line-3 {
  display: grid;
  grid-template-columns: 140px 110px 1fr 200px 130px 80px;
  grid-template-rows: 1fr;
  grid-template-areas: "date id descr seller status price";
}
@media (max-width: 1469.98px) {
  .list-wrapping-top ul.line-3 {
    grid-template-columns: 83px 90px 1fr 200px 130px 80px;
    font-size: 1em;
  }
}
.list-wrapping-top ul.line-3 .date {
  grid-area: date;
}
.list-wrapping-top ul.line-3 .id {
  grid-area: id;
}
.list-wrapping-top ul.line-3 .descr {
  grid-area: descr;
}
.list-wrapping-top ul.line-3 .seller {
  grid-area: seller;
}
.list-wrapping-top ul.line-3 .status {
  grid-area: status;
}
.list-wrapping-top ul.line-3 .price {
  grid-area: price;
}
.list-wrapping-top ul.line-operation {
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
  grid-template-rows: 1fr;
  grid-template-areas: "date id type stat sum";
}
.list-wrapping-top ul.line-operation .date {
  grid-area: date;
}
.list-wrapping-top ul.line-operation .id {
  grid-area: id;
}
.list-wrapping-top ul.line-operation .type {
  grid-area: type;
}
.list-wrapping-top ul.line-operation .stat {
  grid-area: stat;
}
.list-wrapping-top ul.line-operation .sum {
  grid-area: sum;
}
.list-wrapping-top ul.line-appeal {
  display: grid;
  grid-template-columns: 35% 13% 22% 20% 10%;
  grid-template-rows: 1fr;
  grid-template-areas: "subject id status date btns";
}
.list-wrapping-top ul.line-appeal .subject {
  grid-area: subject;
}
.list-wrapping-top ul.line-appeal .id {
  grid-area: id;
}
.list-wrapping-top ul.line-appeal .status {
  grid-area: status;
}
.list-wrapping-top ul.line-appeal .date {
  grid-area: date;
}
.list-wrapping-top ul.line-appeal .btns {
  grid-area: btns;
}
.list-wrapping-main {
  padding: 0px;
}
.list-wrapping-main ul {
  list-style: none;
  padding-left: 0px;
}
.list-wrapping-main ul li {
  border-bottom: 1px solid rgba(157, 196, 239, 0.2);
}
@media (max-width: 1289.98px) {
  .list-wrapping-main ul li {
    border-bottom: none;
  }
}
.list-wrapping-bottom {
  padding: var(--padX) var(--padX);
}
@media (max-width: 1289.98px) {
  .list-wrapping-bottom {
    padding: var(--padY) 0 0;
  }
}

body {
  margin: 0;
  font-family: "Open Sans", "Arial", "Tahoma", "Verdana";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: 400;
  color: #1b1b1c;
  overflow-x: hidden;
}
@media (max-width: 575.98px) {
  body {
    font-size: 13px;
  }
}

main {
  min-height: calc(100vh - var(--h-height) - var(--f-height));
  padding: 2em 0;
}
@media (max-width: 1109.98px) {
  main {
    min-height: calc(100vh - var(--f-height));
    padding-bottom: var(--f-height);
  }
}
@media (max-width: 575.98px) {
  main {
    padding: 1em 0;
  }
}

header {
  background-color: #ffffff;
  position: relative;
  z-index: 1042;
  height: var(--h-height);
}
@media (max-width: 1109.98px) {
  header {
    display: none;
  }
}
header input {
  border-color: #9dc4ef;
}
header input::-moz-placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
header input::placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
header .profile-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
header .profile-link img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 0.5em;
}

/* Slider */
@media (max-width: 1109.98px) {
  .main-slider {
    overflow: hidden;
    position: relative;
    padding-bottom: 2em;
  }
}
@media (max-width: 575.98px) {
  .main-slider {
    padding-bottom: 1.5em;
  }
}
.main-slider-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (max-width: 1109.98px) {
  .main-slider-box {
    width: 300%;
    transition: 400ms ease-in-out;
  }
  .main-slider-box.pos-1 {
    transform: translateX(0%);
  }
  .main-slider-box.pos-2 {
    transform: translateX(-33.3333333333%);
  }
  .main-slider-box.pos-3 {
    transform: translateX(-66.6666666667%);
  }
}
.main-slider-item, .main-slider-item-active {
  --w: 15.5%;
  width: var(--w);
  position: relative;
  padding-top: 3.5em;
  transition: 400ms ease-in-out;
}
@media (max-width: 1289.98px) {
  .main-slider-item, .main-slider-item-active {
    --w: 16.5%;
    padding-top: 2.5em;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item, .main-slider-item-active {
    --w: 100%;
    padding-top: 0em;
  }
}
.main-slider-item .svg-mini, .main-slider-item-active .svg-mini {
  position: relative;
  z-index: 1;
  width: 112%;
  height: 372px;
}
@media (max-width: 1469.98px) {
  .main-slider-item .svg-mini, .main-slider-item-active .svg-mini {
    height: 320px;
  }
}
@media (max-width: 1289.98px) {
  .main-slider-item .svg-mini, .main-slider-item-active .svg-mini {
    height: 284px;
  }
}
.main-slider-item .svg-mobile, .main-slider-item-active .svg-mobile {
  width: 100%;
  height: 552px;
}
@media (max-width: 767.98px) {
  .main-slider-item .svg-mobile, .main-slider-item-active .svg-mobile {
    height: 410px;
  }
}
@media (max-width: 575.98px) {
  .main-slider-item .svg-mobile, .main-slider-item-active .svg-mobile {
    height: 73vw;
  }
}
.main-slider-item .title-full, .main-slider-item-active .title-full {
  position: absolute;
  z-index: 10;
  top: 0%;
  left: 16%;
  width: 84%;
  overflow: hidden;
  opacity: 0;
  transition: 400ms linear 0ms;
}
@media (max-width: 1109.98px) {
  .main-slider-item .title-full, .main-slider-item-active .title-full {
    top: 5%;
    left: 30%;
    width: 70%;
  }
}
@media (max-width: 767.98px) {
  .main-slider-item .title-full, .main-slider-item-active .title-full {
    top: 3%;
  }
}
.main-slider-item .title-full h2, .main-slider-item-active .title-full h2, .main-slider-item .title-full .h2, .main-slider-item-active .title-full .h2 {
  line-height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
@media (max-width: 1289.98px) {
  .main-slider-item .title-full h2, .main-slider-item-active .title-full h2, .main-slider-item .title-full .h2, .main-slider-item-active .title-full .h2 {
    font-size: 2.5em;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item .title-full h2, .main-slider-item-active .title-full h2, .main-slider-item .title-full .h2, .main-slider-item-active .title-full .h2 {
    font-size: 3em;
  }
}
@media (max-width: 767.98px) {
  .main-slider-item .title-full h2, .main-slider-item-active .title-full h2, .main-slider-item .title-full .h2, .main-slider-item-active .title-full .h2 {
    font-size: 2.5em;
  }
}
@media (max-width: 575.98px) {
  .main-slider-item .title-full h2, .main-slider-item-active .title-full h2, .main-slider-item .title-full .h2, .main-slider-item-active .title-full .h2 {
    font-size: 2.3em;
  }
}
.main-slider-item .title-mini, .main-slider-item-active .title-mini {
  margin-top: 10px;
  width: 100%;
  overflow: hidden;
  opacity: 1;
  transition: 400ms linear 400ms;
}
@media (max-width: 1289.98px) {
  .main-slider-item .title-mini, .main-slider-item-active .title-mini {
    margin-top: 0px;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item .title-mini, .main-slider-item-active .title-mini {
    display: none;
  }
}
.main-slider-item .title-mini h2, .main-slider-item-active .title-mini h2, .main-slider-item .title-mini .h2, .main-slider-item-active .title-mini .h2 {
  line-height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-size: 2.5em;
}
@media (max-width: 1469.98px) {
  .main-slider-item .title-mini h2, .main-slider-item-active .title-mini h2, .main-slider-item .title-mini .h2, .main-slider-item-active .title-mini .h2 {
    font-size: 2em;
  }
}
.main-slider-item .lots, .main-slider-item-active .lots {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  z-index: 10;
  bottom: 9%;
  right: -6%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #ffffff;
  font-size: 0.75em;
}
@media (max-width: 1469.98px) {
  .main-slider-item .lots, .main-slider-item-active .lots {
    font-size: 0.6em;
  }
}
@media (max-width: 1289.98px) {
  .main-slider-item .lots, .main-slider-item-active .lots {
    font-size: 0.55em;
  }
}
.main-slider-item .lots .num, .main-slider-item-active .lots .num {
  font-size: 1.1em;
  line-height: 140%;
  font-weight: 600;
}
@media (max-width: 1469.98px) {
  .main-slider-item .lots .num, .main-slider-item-active .lots .num {
    font-size: 1.25em;
  }
}
.main-slider-item .link, .main-slider-item-active .link {
  position: absolute;
  z-index: 10;
  bottom: 2em;
  right: 2em;
  opacity: 0;
  color: #ffffff;
  text-decoration: none;
}
@media (max-width: 1289.98px) {
  .main-slider-item .link, .main-slider-item-active .link {
    bottom: 1em;
    right: 1em;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item .link, .main-slider-item-active .link {
    display: none;
  }
}
.main-slider-item-active {
  --w: 60.6%;
}
@media (max-width: 1469.98px) {
  .main-slider-item-active {
    --w: 61%;
  }
}
@media (max-width: 1289.98px) {
  .main-slider-item-active {
    --w: 62%;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item-active {
    --w: 100%;
  }
}
.main-slider-item-active .svg-full {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 458px;
  margin-top: -3em;
}
@media (max-width: 1469.98px) {
  .main-slider-item-active .svg-full {
    height: 400px;
    margin-top: -2.6em;
  }
}
@media (max-width: 1289.98px) {
  .main-slider-item-active .svg-full {
    height: 340px;
    margin-top: -2em;
  }
}
.main-slider-item-active .title-full {
  opacity: 1;
  transition: 400ms linear 400ms;
}
.main-slider-item-active .title-mini {
  height: 0px;
  opacity: 0;
  transition: 400ms linear 0ms;
}
.main-slider-item-active .lots {
  top: -1.85em;
  left: 6.25%;
  bottom: unset;
  right: unset;
  font-size: 0.9em;
}
@media (max-width: 1469.98px) {
  .main-slider-item-active .lots {
    font-size: 0.8em;
  }
}
@media (max-width: 1289.98px) {
  .main-slider-item-active .lots {
    font-size: 0.7em;
    top: -1.65em;
  }
}
@media (max-width: 1109.98px) {
  .main-slider-item-active .lots {
    font-size: 1.3em;
    top: 7%;
    left: 12.5%;
  }
}
@media (max-width: 767.98px) {
  .main-slider-item-active .lots {
    font-size: 1em;
  }
}
@media (max-width: 575.98px) {
  .main-slider-item-active .lots {
    font-size: 3vw;
    left: 12%;
  }
}
.main-slider-item-active .lots .num {
  line-height: 120%;
  font-size: 1.6em;
  font-weight: 400;
}
.main-slider-item-active .link {
  opacity: 1;
  transition: 200ms ease-in-out 800ms;
}
.main-slider-prev, .main-slider-next {
  display: flex;
  position: absolute;
  top: 67.5%;
  font-size: 2em;
}
@media (max-width: 767.98px) {
  .main-slider-prev, .main-slider-next {
    top: 66.5%;
    font-size: 1.5em;
  }
}
@media (max-width: 575.98px) {
  .main-slider-prev, .main-slider-next {
    font-size: 1.25em;
  }
}
.main-slider-prev {
  left: 0px;
}
.main-slider-next {
  right: 0px;
}
.main-slider-indicators {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.main-slider-indicators li {
  flex-grow: 1;
  height: 0.6em;
  background-color: rgba(27, 27, 28, 0.15);
  border-radius: 0.3em;
  transition: 300ms ease-in-out;
}
@media (max-width: 575.98px) {
  .main-slider-indicators li {
    height: 0.4em;
  }
}
.main-slider-indicators li:not(:first-child) {
  margin-left: 2em;
}
.main-slider-indicators li.active {
  background-color: #1b1b1c;
}

/* Catalog */
.sec-catalog {
  background: url(imgs/bg/gradient.jpg) no-repeat;
  background-size: 100% 100%;
}
.sec-catalog .container {
  position: relative;
}
@media (max-width: 1109.98px) {
  .sec-catalog .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}
.sec-catalog-nav {
  position: absolute;
  z-index: 10;
  top: 0%;
  right: calc(100% + 12px);
  width: 60px;
  height: 100%;
}
@media (max-width: 1109.98px) {
  .sec-catalog-nav {
    position: relative;
    top: unset;
    right: unset;
    height: auto;
    margin-left: 1em;
  }
}
@media (max-width: 575.98px) {
  .sec-catalog-nav {
    width: 40px;
  }
}
.sec-catalog-nav .wrap {
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
  position: sticky;
  top: 0.5em;
  left: 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 2em);
  padding: 0.5em;
  transition: 300ms ease-in-out;
}
@media (max-width: 1109.98px) {
  .sec-catalog-nav .wrap {
    float: right;
  }
}
@media (max-width: 575.98px) {
  .sec-catalog-nav .wrap {
    padding: 0;
  }
}
.sec-catalog-nav .wrap form {
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.67em;
  margin-bottom: 0.25em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  transition: 300ms ease-in-out;
}
.sec-catalog-nav .wrap form button {
  position: absolute;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  width: 3em;
  color: #9dc4ef;
}
.sec-catalog-nav .wrap form button svg {
  font-size: 1.6em;
}
.sec-catalog-nav .wrap form input {
  width: 100%;
  opacity: 0;
  position: relative;
  z-index: 1;
  padding: 0.6em 0 0.6em 3em;
}
.sec-catalog-nav .wrap form input::-moz-placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
.sec-catalog-nav .wrap form input::placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
.sec-catalog-nav .wrap ul {
  list-style: none;
  padding-left: 0px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(9, 1fr);
  grid-auto-flow: column;
}
.sec-catalog-nav .wrap ul li {
  grid-column: span 1;
  grid-row: span 1;
  font-size: 1.3em;
  text-align: center;
  padding: 0.4em;
}
.sec-catalog-nav .wrap ul li a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #1b1b1c;
  position: relative;
  background-color: transparent;
  transition: 300ms ease-in-out;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  white-space: nowrap;
}
.sec-catalog-nav .wrap ul li a:hover {
  background-color: rgba(157, 196, 239, 0.25);
}
.sec-catalog-nav .wrap ul li a.ss-active {
  background-color: rgba(157, 196, 239, 0.5);
}
.sec-catalog-nav .wrap #cut {
  font-size: 1.3em;
  position: absolute;
  top: 0px;
  right: -0.5em;
  width: 1em;
  height: 1em;
  transform: translateY(0.25em);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 1109.98px) {
  .sec-catalog-nav .wrap #cut {
    right: unset;
    left: -0.5em;
  }
}
.sec-catalog-nav .wrap #cut img {
  width: 100%;
  height: 100%;
  transition: 300ms ease-in-out;
}
@media (max-width: 1109.98px) {
  .sec-catalog-nav .wrap #cut img {
    transform: rotate(-180deg);
  }
}
.sec-catalog-nav .wrap #cut.opened img {
  transform: rotate(-180deg);
}
@media (max-width: 1109.98px) {
  .sec-catalog-nav .wrap #cut.opened img {
    transform: rotate(0deg);
  }
}
.sec-catalog-nav .wrap.full {
  width: 180px;
}
@media (max-width: 575.98px) {
  .sec-catalog-nav .wrap.full {
    width: 130px;
  }
}
.sec-catalog-nav .wrap.full form input {
  opacity: 1;
}
.sec-catalog-box {
  flex: 1;
}
.sec-catalog-part {
  padding: 1em 1.5em;
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 10px;
}
.sec-catalog-part:not(:last-child) {
  margin-bottom: 3.33em;
}
@media (max-width: 575.98px) {
  .sec-catalog-part {
    padding: 1em;
  }
}
.sec-catalog .letter {
  font-size: 3.33em;
  font-weight: 700;
  color: rgba(67, 185, 198, 0.26);
  margin-bottom: 1rem;
}
@media (max-width: 575.98px) {
  .sec-catalog .letter {
    line-height: 100%;
  }
}

.game-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.game-card .img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5em;
}
.game-card h4, .game-card .h4 {
  padding: 0 0 0.6em 0.65em;
}
.game-card .categories {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.85em 0.5em 0.5em;
}
@media (max-width: 1469.98px) {
  .game-card .categories {
    padding: 0.75em 0em 0;
  }
}
.game-card .categories li {
  margin-bottom: 0.67em;
}
.game-card .categories li:not(:last-child) {
  margin-right: 1em;
}
.game-card-mini {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff;
  border-radius: 0.67em;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  padding: 1.6em 1.2em;
}
@media (max-width: 575.98px) {
  .game-card-mini {
    padding: 1em 0.75em;
  }
}
.game-card-mini .img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5em;
}
@media (max-width: 575.98px) {
  .game-card-mini .img {
    width: 30px;
    height: 30px;
  }
}
.game-card-mini h6, .game-card-mini .h6 {
  font-size: 1.1em;
}

.servers {
  position: relative;
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background: rgba(72, 216, 104, 0.8);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.3), 0px 3px 10px rgba(156, 156, 156, 0.2);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0.67em;
  padding: 0 0.5em;
}
.servers li {
  position: relative;
  color: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  padding: 0.6em 0.5em;
  transition: 300ms ease-in-out;
  cursor: pointer;
}
.servers li.indicator {
  transform: translateY(-50%) translateX(-50%);
  padding: 0px;
  position: absolute;
  left: 0.5em;
  top: -1px;
  width: 20px;
  height: 14px;
  background: url(imgs/Subtract.svg) no-repeat center bottom;
  background-size: 100% 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.servers li.indicator img {
  width: 8px;
  height: 8px;
  filter: drop-shadow(0px 1px 3px rgba(70, 67, 118, 0.2));
}
.servers li.active {
  color: #005a47;
}

.sec-promo {
  position: relative;
  background: url(imgs/bg/texture.png), linear-gradient(109.93deg, #3399ab 26.37%, #78d848 59.52%, #91ee8f 91.99%);
  background-size: cover, 100% 100%;
  background-repeat: no-repeat;
  background-blend-mode: overlay, normal;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
  padding: 0 10% 0 4em;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10%;
}
@media (max-width: 1469.98px) {
  .sec-promo {
    padding: 0 3em;
  }
}
@media (max-width: 1289.98px) {
  .sec-promo {
    padding: 0 2em;
  }
}
@media (max-width: 1109.98px) {
  .sec-promo {
    margin-top: 15%;
  }
}
@media (max-width: 575.98px) {
  .sec-promo {
    padding: 1em;
    height: 85px;
  }
}
.sec-promo img {
  width: 25%;
  margin-top: -11%;
}
@media (max-width: 1289.98px) {
  .sec-promo img {
    width: 22%;
    margin-top: -7%;
  }
}
@media (max-width: 1109.98px) {
  .sec-promo img {
    width: 50%;
    margin-top: -15%;
  }
}
@media (max-width: 575.98px) {
  .sec-promo img {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 55%;
  }
}
.sec-promo .text {
  position: relative;
  z-index: 10;
}

.page-game {
  background: url(imgs/bg/gradient.jpg) no-repeat center bottom;
  background-size: 100% 65%;
}
.page-game-top {
  position: relative;
}
.page-game-top .img {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 45%;
  height: auto;
  z-index: 1;
}
@media (max-width: 1469.98px) {
  .page-game-top .img {
    width: 50%;
  }
}
@media (max-width: 1289.98px) {
  .page-game-top .img {
    width: 100%;
  }
}
.page-game-top .img svg {
  width: 100%;
  height: auto;
}
.page-game-top .img-lots {
  position: absolute;
  top: 14%;
  left: 10.7%;
  text-align: center;
  color: #ffffff;
}
.page-game-top .img-lots .num {
  font-size: 1.6em;
}
@media (max-width: 575.98px) {
  .page-game-top .img-lots {
    font-size: 2.5vw;
  }
}
.page-game-top .categories {
  position: unset;
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1em;
  margin-bottom: 2em;
}
@media (max-width: 1469.98px) {
  .page-game-top .categories {
    margin-bottom: 1em;
  }
}
@media (max-width: 1289.98px) {
  .page-game-top .categories {
    position: relative;
    width: 60%;
    height: 460px;
    align-content: flex-end;
    justify-content: center;
    margin-bottom: 0em;
  }
}
@media (max-width: 1109.98px) {
  .page-game-top .categories {
    width: 80%;
  }
}
@media (max-width: 767.98px) {
  .page-game-top .categories {
    width: 100%;
    height: 430px;
  }
}
@media (max-width: 575.98px) {
  .page-game-top .categories {
    height: calc(85vw - 24px);
  }
}
.page-game-top .categories li {
  font-size: 1.2em;
  margin-right: 1.1em;
  margin-bottom: 1.1em;
}
@media (max-width: 1289.98px) {
  .page-game-top .categories li {
    margin-bottom: 0.6em;
    position: relative;
    z-index: 10;
  }
}
@media (max-width: 767.98px) {
  .page-game-top .categories li {
    font-size: 1em;
  }
}
.page-game-top .categories li button,
.page-game-top .categories li a {
  background-color: rgba(157, 196, 239, 0.2);
  padding: 0.55em;
  box-shadow: 0px 4px 15px rgba(100, 100, 100, 0.08);
  border-radius: 0.335em;
  transition: 300ms ease-in-out;
}
.page-game-top .categories li button:hover, .page-game-top .categories li button:focus,
.page-game-top .categories li a:hover,
.page-game-top .categories li a:focus {
  background-color: rgba(157, 196, 239, 0.6);
}
.page-game-top .categories li button:active, .page-game-top .categories li button.active,
.page-game-top .categories li a:active,
.page-game-top .categories li a.active {
  background-color: #9dc4ef;
  color: #ffffff;
}
@media (max-width: 1289.98px) {
  .page-game-top .categories li button,
  .page-game-top .categories li a {
    background-color: rgba(157, 196, 239, 0.85);
  }
  .page-game-top .categories li button:hover, .page-game-top .categories li button:focus, .page-game-top .categories li button:active, .page-game-top .categories li button.active,
  .page-game-top .categories li a:hover,
  .page-game-top .categories li a:focus,
  .page-game-top .categories li a:active,
  .page-game-top .categories li a.active {
    background-color: #4a83dc;
    color: #ffffff;
  }
}
.page-game-top .filter {
  position: relative;
  z-index: 10;
}
.page-game-top .filter fieldset {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1109.98px) {
  .page-game-top .filter fieldset {
    margin-top: 1em;
  }
}
@media (max-width: 575.98px) {
  .page-game-top .filter fieldset > * {
    width: 100% !important;
  }
}
.page-game-top .filter input:not([type=checkbox]) {
  border-color: #9dc4ef;
}
.page-game-top .filter input:not([type=checkbox])::-moz-placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
.page-game-top .filter input:not([type=checkbox])::placeholder {
  color: #9dc4ef;
  font-weight: 400;
}
.page-game-top .filter input[type=search] {
  width: 340px;
}
@media (max-width: 1469.98px) {
  .page-game-top .filter input[type=search] {
    width: 270px;
  }
}
@media (max-width: 767.98px) {
  .page-game-top .filter input[type=search] {
    width: 200px;
  }
}
.page-game-top .filter input[type=number] {
  width: 70px;
  padding: 0.7em 0.75em;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .page-game-top .filter {
    background: rgba(255, 255, 255, 0.75);
    padding: 1em;
    border-radius: 0.67em;
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 4px 15px rgba(100, 100, 100, 0.08);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
  }
}
.page-game-offers {
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
  --pad: 15px 20px;
  --serv: 220px;
  --seller: 220px;
  --availability: 165px;
  --price: 120px;
}
@media (max-width: 1289.98px) {
  .page-game-offers {
    background: transparent;
    border-radius: 0px;
    box-shadow: none;
    --pad: 0px 1em;
  }
}
@media (max-width: 767.98px) {
  .page-game-offers {
    --pad: 0px 0.5em;
    --price: 90px;
  }
}
.page-game-offers .top {
  background-color: rgba(157, 196, 239, 0.2);
  border-radius: 0.67em 0.67em 0px 0px;
  padding: 0 2em;
  display: grid;
  grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(--price);
  grid-template-rows: 1fr;
  grid-template-areas: "serv descr seller availability price";
}
@media (max-width: 1289.98px) {
  .page-game-offers .top {
    display: none;
  }
}
.page-game-offers .top div {
  font-size: 1.2em;
  font-weight: 600;
  padding: var(--pad);
}
.page-game-offers .top div.serv {
  grid-area: serv;
}
.page-game-offers .top div.descr {
  grid-area: descr;
}
.page-game-offers .top div.seller {
  grid-area: seller;
}
.page-game-offers .top div.availability {
  grid-area: availability;
  text-align: center;
}
.page-game-offers .top div.price {
  grid-area: price;
  text-align: center;
}
.page-game-offers ul {
  list-style: none;
  padding-left: 0px;
  padding: 2em;
}
@media (max-width: 1289.98px) {
  .page-game-offers ul {
    padding: 0px;
  }
}

.offer-line {
  display: grid;
  grid-template-columns: var(--serv) auto var(--seller) var(--availability) var(--price);
  grid-template-rows: 1fr;
  grid-template-areas: "serv descr seller availability price";
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(100, 100, 100, 0.08);
  border-radius: 0.67em;
}
@media (max-width: 1289.98px) {
  .offer-line {
    grid-template-columns: auto var(--price);
    grid-template-rows: 2em auto 3em;
    grid-template-areas: "serv price" "descr descr" "seller availability";
    gap: 10px 0px;
    padding: 1em 0;
  }
}
@media (max-width: 767.98px) {
  .offer-line {
    padding: 0.5em 0;
  }
}
.offer-line > div {
  grid-column: span 1;
  grid-row: span 1;
  padding: var(--pad);
}
.offer-line .serv {
  grid-area: serv;
}
@media (max-width: 1289.98px) {
  .offer-line .serv {
    color: #4a83dc;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
}
.offer-line .descr {
  grid-area: descr;
}
@media (max-width: 1289.98px) {
  .offer-line .descr {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 3.6em;
  }
}
.offer-line .seller {
  grid-area: seller;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media (min-width: 576px) and (max-width: 1289.98px) {
  .offer-line .seller {
    font-size: 0.8em;
  }
}
.offer-line .seller img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  margin-right: 0.75em;
}
@media (max-width: 1289.98px) {
  .offer-line .seller img {
    width: 40px;
    height: 40px;
  }
}
.offer-line .availability {
  grid-area: availability;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .offer-line .availability {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    text-align: right;
  }
}
.offer-line .price {
  grid-area: price;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .offer-line .price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    text-align: right;
    font-weight: 600;
  }
}

/* Trader page */
.trader-line {
  display: grid;
  grid-template-columns: 160px auto 120px;
  grid-template-rows: 1fr;
  grid-template-areas: "serv descr price";
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(100, 100, 100, 0.08);
  border-radius: 0.67em;
  border-bottom: none;
}
.trader-line > div {
  grid-column: span 1;
  grid-row: span 1;
  padding: 1em;
}
.trader-line .serv {
  grid-area: serv;
}
@media (max-width: 1289.98px) {
  .trader-line .serv {
    color: #4a83dc;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
}
.trader-line .descr {
  grid-area: descr;
}
@media (max-width: 1289.98px) {
  .trader-line .descr {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    height: 3.6em;
  }
}
.trader-line .price {
  grid-area: price;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .trader-line .price {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    text-align: right;
    font-weight: 600;
  }
}

/* Lot page */
.lot-page .specifications {
  list-style: none;
  padding-left: 0px;
  font-size: 0.9em;
}
.lot-page .specifications li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
.lot-page .specifications li::after {
  content: "";
  position: absolute;
  bottom: 0.2em;
  left: 0em;
  width: 100%;
  height: 1px;
  background-color: rgba(157, 196, 239, 0.25);
}
.lot-page .specifications li:not(:first-child) {
  margin-top: 0.5em;
}
.lot-page .specifications li span {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 0em 0.5em;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lot-page .specifications li span:first-child {
  color: #8f8f8f;
}
.lot-page .specifications li span:last-child {
  color: #4a83dc;
  width: 45%;
}
@media (max-width: 575.98px) {
  .lot-page .specifications li span:last-child {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 1em;
  }
}
.lot-page-box {
  border: 1px solid rgba(157, 196, 239, 0.25);
  border-radius: 0.67em;
}
.lot-page-box hr {
  background-color: rgba(157, 196, 239, 0.25);
  width: 100%;
  height: 1px;
  border: none;
  margin: 0px;
  opacity: 1;
}
.lot-page-box .seller {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.lot-page-box .seller img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 1em;
}
.lot-page-box .seller .rating {
  font-size: 1.2em;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.lot-page-box .seller .rating svg {
  color: #4a83dc;
  font-size: 1.1em;
}
.lot-page-box .seller .rating svg path {
  fill: #4a83dc;
}
.lot-page-grid {
  display: grid;
  grid-template-columns: 120px 4fr 5fr;
  grid-template-rows: 35px 40px 30px 85px 70px;
  gap: 0px 0px;
  grid-template-areas: "game . date" "game info info" "game title title" "payment payment payment" "text text specifications";
}
@media (max-width: 1289.98px) {
  .lot-page-grid {
    grid-template-columns: 120px 3fr 5fr;
  }
}
@media (max-width: 767.98px) {
  .lot-page-grid {
    grid-template-columns: 120px auto;
    grid-template-rows: auto;
    grid-template-areas: "game date" "game info" "title title" "text text" "specifications specifications" "payment payment";
  }
}
@media (max-width: 575.98px) {
  .lot-page-grid {
    grid-template-columns: 100px auto;
  }
}
.lot-page-grid .game {
  grid-area: game;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .lot-page-grid .game {
    padding: 1em;
  }
}
.lot-page-grid .game img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.lot-page-grid .date {
  grid-area: date;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 0px 1em;
  color: #c1c1c1;
}
@media (max-width: 575.98px) {
  .lot-page-grid .date {
    align-items: center;
  }
}
.lot-page-grid .date time {
  font-size: 0.8em;
}
.lot-page-grid .info {
  grid-area: info;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 575.98px) {
  .lot-page-grid .info {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    flex-wrap: nowrap;
  }
}
.lot-page-grid .info .tag-gray {
  background-color: #ecf4fc;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
}
.lot-page-grid .info .tag-green {
  background-color: #48d868;
  color: #ffffff;
  padding: 0.25em 0.5em;
  border-radius: 0.5em;
}
.lot-page-grid .title {
  grid-area: title;
  color: #4a83dc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .lot-page-grid .title {
    padding: 1em;
  }
}
@media (min-width: 768px) {
  .lot-page-grid .title p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
.lot-page-grid .payment {
  grid-area: payment;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 1em;
  border-bottom: 1px solid rgba(157, 196, 239, 0.25);
}
@media (max-width: 767.98px) {
  .lot-page-grid .payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    flex-wrap: nowrap;
    border-bottom: none;
  }
  .lot-page-grid .payment button {
    width: 100%;
    margin-top: 0.5em;
  }
}
.lot-page-grid .text {
  grid-area: text;
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .lot-page-grid .text {
    padding-top: 0px;
    border-bottom: 1px solid rgba(157, 196, 239, 0.25);
  }
}
.lot-page-grid .specifications {
  grid-area: specifications;
  padding: 1em;
}
@media (max-width: 1469.98px) {
  .lot-page-grid .specifications {
    font-size: 0.8em;
  }
}
@media (max-width: 767.98px) {
  .lot-page-grid .specifications {
    font-size: 1em;
  }
}
@media (max-width: 575.98px) {
  .lot-page-grid .specifications {
    font-size: 0.9em;
  }
}

.review-card {
  border: 1px solid rgba(157, 196, 239, 0.25);
  background-color: rgba(157, 196, 239, 0.1);
  border-radius: 0.67em;
  padding: 0.75em;
}
.review-card .user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 1469.98px) {
  .review-card .user {
    flex: 1;
  }
}
.review-card .user-photo {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  border: none;
  margin-right: 0.5em;
}
.review-card time {
  font-size: 0.8em;
  color: #9dc4ef;
}
.review-card .rating {
  font-size: 1.2em;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.review-card .rating svg {
  color: #4a83dc;
  font-size: 1.1em;
}
.review-card .rating svg path {
  fill: #4a83dc;
}

/* Blog */
.sec-blog .blog-list {
  list-style: none;
  padding-left: 0px;
}
.sec-blog .blog-list-item {
  position: relative;
}
.sec-blog .blog-list-item img {
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.67em;
  margin-bottom: 1.3em;
}
@media (max-width: 1469.98px) {
  .sec-blog .blog-list-item img {
    height: 190px;
  }
}
.sec-blog .blog-list-item h5, .sec-blog .blog-list-item .h5 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3em;
  max-height: 3.9em;
}
.sec-blog .blog-list-item-mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
}
.sec-blog .blog-list-item-mobile img {
  width: 100px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.67em;
  margin-right: 1em;
}
.sec-blog .blog-list-item-mobile h5, .sec-blog .blog-list-item-mobile .h5 {
  flex: 1;
}

.cognition {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #1b1b1c;
  background-image: linear-gradient(to right, transparent, #1b1b1c), url(imgs/bg/cogito.jpg);
  background-repeat: no-repeat;
  background-size: 50% 100%, 75% auto;
  background-position: 60% 50%, -10% 50%;
  color: #ffffff;
  padding: 0em 2em;
  height: 60px;
  text-align: right;
  border-radius: 0.67em;
  transition: 300ms ease-in-out;
}
.cognition:hover, .cognition:focus, .cognition:active {
  color: #66c6e4;
}

.page-blog .article-mini {
  position: relative;
}
.page-blog .article-mini img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.67em 0.67em 0 0;
}
@media (max-width: 1469.98px) {
  .page-blog .article-mini img {
    height: 220px;
  }
}
@media (max-width: 1289.98px) {
  .page-blog .article-mini img {
    height: 180px;
  }
}
@media (max-width: 1109.98px) {
  .page-blog .article-mini img {
    height: 200px;
  }
}
@media (max-width: 767.98px) {
  .page-blog .article-mini img {
    height: 155px;
  }
}
@media (max-width: 575.98px) {
  .page-blog .article-mini img {
    height: 33vw;
  }
}
.page-blog .article-mini figcaption {
  background-color: #ffffff;
  border-radius: 0 0 0.67em 0.67em;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  padding: 1.5em 1.5em 0.6em;
}
@media (max-width: 1289.98px) {
  .page-blog .article-mini figcaption {
    padding: 1em 1.25em 0.6em;
  }
}
@media (max-width: 575.98px) {
  .page-blog .article-mini figcaption {
    padding: 0.6em 1em;
  }
}
.page-blog .article-mini figcaption h5, .page-blog .article-mini figcaption .h5 {
  font-weight: 600;
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
}
.page-blog .article-mini figcaption h5 a, .page-blog .article-mini figcaption .h5 a {
  line-height: 1.2em;
}
.page-blog .article-mini figcaption time {
  display: block;
  text-align: right;
  margin-top: 0.5em;
  color: #9dc4ef;
  font-weight: 500;
  font-size: 1.2em;
}
@media (max-width: 1109.98px) {
  .page-blog .article-mini figcaption time {
    font-size: 1em;
  }
}
.page-blog article p {
  color: #000;
  margin-bottom: 2em;
}
@media (max-width: 575.98px) {
  .page-blog article p {
    margin-bottom: 1em;
  }
}
.page-blog article img {
  max-width: 100%;
  border-radius: 0.67em;
  margin-bottom: 2em;
}
@media (max-width: 575.98px) {
  .page-blog article img {
    margin-bottom: 1em;
  }
}

/* bottom background */
.sec-bottom {
  background: url(imgs/bg/gradient.jpg) no-repeat center;
  background-size: 100% 100%;
}

/* Chat */
.sec-chat h2, .sec-chat .h2 {
  margin-left: 3.5em;
  margin-bottom: 0.1em;
}

.sec-messages-chat {
  box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
}

.chat {
  position: relative;
}
.chat-window {
  height: 698px;
  overflow: auto;
  padding: 1em 1.5em;
}
@media (max-width: 1109.98px) {
  .chat-window {
    padding: 1em 1.5em;
    height: 500px;
  }
}
@media (max-width: 767.98px) {
  .chat-window {
    padding: 1em;
  }
}
@media (max-width: 575.98px) {
  .chat-window {
    padding: 1em 0.5em;
  }
}
.chat-window-message, .chat-window-message-mine {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.chat-window-message:not(:first-child), .chat-window-message-mine:not(:first-child) {
  margin-top: 1.5em;
}
.chat-window-message img, .chat-window-message-mine img {
  width: 3.34em;
  height: 3.34em;
  border-radius: 50%;
  position: relative;
  z-index: 10;
  margin-right: 1em;
}
.chat-window-message .text, .chat-window-message-mine .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.chat-window-message .text .bubble, .chat-window-message-mine .text .bubble {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 50%;
  background: rgba(157, 196, 239, 0.2);
  border-radius: 0.67em;
  padding: 1em;
  font-weight: 400;
  font-size: 0.9em;
  text-align: left;
}
.chat-window-message-mine img {
  margin-left: 1em;
  margin-right: 0px;
}
.chat-window-message-mine .text {
  align-items: flex-end;
}
.chat-window-message-mine .text .bubble {
  background: rgba(148, 229, 166, 0.2);
  text-align: right;
}
.chat-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  border-top: 1px solid rgba(157, 196, 239, 0.3);
  padding: 0.5em;
}
.chat-form input {
  border: none;
  background-color: transparent;
  height: 100%;
}

/* Popular products */
.sec-popular h2, .sec-popular .h2 {
  margin-bottom: 0.1em;
  padding: 0 0.35em;
  font-size: 2.85em;
}
@media (max-width: 1289.98px) {
  .sec-popular h2, .sec-popular .h2 {
    font-size: 2.65em;
  }
}
.sec-popular .offers-slider {
  height: 860px;
  padding: 0 1.5em 0 1em;
  margin-right: -0.5em;
}
.sec-popular .offers-slider .swiper-scrollbar {
  right: 0.5em;
  background-color: #fff;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 1px 3px 20px rgba(100, 100, 100, 0.15);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  width: 5px;
}
.sec-popular .offers-slider .swiper-scrollbar-drag {
  background-color: #9dc4ef;
}
.sec-popular .offers-slider .swiper-slide {
  height: -moz-fit-content;
  height: fit-content;
}
.sec-popular .offers-slider .offer-card {
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border-radius: 0.67em;
  padding: 1.6em 1.6em 0.7em 1.6em;
}
.sec-popular .offers-slider .offer-card-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.sec-popular .offers-slider .offer-card-top h5, .sec-popular .offers-slider .offer-card-top .h5 {
  font-weight: 700;
  color: #48d868;
}
.sec-popular .offers-slider .offer-card-top h5 h5, .sec-popular .offers-slider .offer-card-top .h5 h5, .sec-popular .offers-slider .offer-card-top h5 .h5, .sec-popular .offers-slider .offer-card-top .h5 .h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4em;
  height: 1.4em;
}
.sec-popular .offers-slider .offer-card-main {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.sec-popular .offers-slider .offer-card-main h5, .sec-popular .offers-slider .offer-card-main .h5 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4em;
  height: 2.8em;
}
.sec-popular .offers-slider .offer-card-text {
  margin-top: 1em;
}
.sec-popular .offers-slider .offer-card-text p {
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4em;
  max-height: 2.8em;
}
.sec-popular .offers-slider .offer-card-bottom {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

/* Registration page */
.sec-registration {
  margin-top: 4em;
}
@media (max-width: 1109.98px) {
  .sec-registration {
    margin-top: 0em;
  }
}
.sec-registration form.midi {
  width: 530px;
}
@media (max-width: 1469.98px) {
  .sec-registration form.midi {
    width: 480px;
  }
}
@media (max-width: 1109.98px) {
  .sec-registration form.midi {
    width: 100%;
  }
}
.sec-registration form.mini {
  width: 440px;
}
@media (max-width: 1469.98px) {
  .sec-registration form.mini {
    width: 330px;
  }
}
@media (max-width: 1109.98px) {
  .sec-registration form.mini {
    width: 100%;
    margin-top: 4em;
  }
}

/* Entering page */
.sec-login .wrap {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 2em 2em 1.5em 2em;
  border-radius: 0.67em;
  height: 100%;
}
@media (max-width: 575.98px) {
  .sec-login .wrap {
    padding: 1.5em 1.5em 1em 1.5em;
  }
}
.sec-login .cover {
  border-radius: 0.67em;
  background: url(imgs/bg/texture.png), linear-gradient(115deg, #1b1b1c 0%, #4a83dc 55%, #000 100%);
  background-size: cover, 100% 100%;
  background-repeat: no-repeat;
  background-blend-mode: overlay, normal;
  color: #ffffff;
  height: 395px;
  padding: 0 5em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
@media (max-width: 1469.98px) {
  .sec-login .cover {
    height: 412px;
    padding: 0 3em;
  }
}
@media (max-width: 1109.98px) {
  .sec-login .cover {
    height: 150px;
    overflow: visible;
    margin-bottom: 2em;
  }
}
@media (max-width: 575.98px) {
  .sec-login .cover {
    height: 100px;
    padding: 0 1em;
    margin-bottom: 1em;
  }
}
.sec-login .cover h2, .sec-login .cover .h2 {
  line-height: 100%;
}
.sec-login .cover img {
  position: absolute;
  right: 0%;
  bottom: 0%;
  height: 85%;
  z-index: 10;
}
@media (max-width: 1469.98px) {
  .sec-login .cover img {
    height: 70%;
  }
}
@media (max-width: 1109.98px) {
  .sec-login .cover img {
    height: 110%;
    right: 15%;
  }
}
@media (max-width: 767.98px) {
  .sec-login .cover img {
    right: 5%;
  }
}
@media (max-width: 575.98px) {
  .sec-login .cover img {
    height: 100%;
  }
}

/* Password restoration page */
.sec-password .wrap {
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 2em 2em 1.5em 2em;
  border-radius: 0.67em;
}
@media (max-width: 575.98px) {
  .sec-password .wrap {
    padding: 1.5em 1.5em 1em 1.5em;
  }
}

.sec-favorites ul {
  list-style: none;
  padding-left: 0px;
}
.sec-favorites .fav-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  padding: 0.65em 1.3em;
  border-radius: 0.67em;
}
@media (max-width: 1469.98px) {
  .sec-favorites .fav-item {
    padding: 0.5em 1em;
  }
}
.sec-favorites .fav-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 1em;
}
.sec-favorites .fav-item h5, .sec-favorites .fav-item .h5 {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  max-height: 2.4em;
}
.sec-favorites .fav-item button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #8f8f8f;
  font-size: 1.35em;
}
.sec-favorites .fav-item button svg {
  stroke-width: 1px;
}

/***********
ACCOUNT
***********/
.account {
  position: relative;
  overflow: hidden;
}
.account .return-icon {
  display: flex;
  font-size: 2em;
  color: #4a83dc;
}
@media (max-width: 1109.98px) {
  .account h1, .account .h1 {
    color: #4a83dc;
    font-size: 1.5em;
  }
}
@media (max-width: 575.98px) {
  .account h1, .account .h1 {
    font-size: 1.25em;
  }
  .account .return-icon {
    font-size: 1.6em;
    color: #4a83dc;
  }
}
.account .account-bg {
  position: absolute;
  top: 60%;
  left: 0px;
  width: 100%;
  height: auto;
  z-index: 1;
}
.account .container {
  position: relative;
  z-index: 2;
}
.account-nav-balance {
  background-color: #4a83dc;
  color: #ffffff;
  padding: 1em;
  border-radius: 0.67em;
  text-align: center;
  margin-bottom: 1em;
}
.account-nav-balance div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.account-nav-balance div span {
  font-family: "Bebas";
  font-size: 2em;
  margin-right: 0.34em;
  line-height: 90%;
}
.account-nav-balance div svg {
  font-size: 1.5em;
  padding: 0.1em;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.account-nav ul {
  list-style: none;
  padding-left: 0px;
}
.account-nav ul li:not(:first-child) {
  margin-top: 1em;
}
@media (max-width: 1109.98px) {
  .account-nav ul li:not(:first-child) {
    margin-top: 0.5em;
  }
}
.account-nav ul li a {
  color: #8f8f8f;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.67em 1em;
  border-radius: 0.67em;
  transition: 300ms ease-in-out;
}
@media (max-width: 1109.98px) {
  .account-nav ul li a {
    font-size: 1.2em;
  }
}
.account-nav ul li a svg {
  font-size: 1.6em;
}
.account-nav ul li a div {
  margin-left: 0.5em;
  flex: 1;
}
.account-nav ul li a .badge {
  background-color: rgba(157, 196, 239, 0.2);
  padding: 0.34em;
  border-radius: 0.67em;
  color: #000;
}
.account-nav ul li a:hover, .account-nav ul li a:focus {
  color: #4a83dc;
}
.account-nav ul li a.active {
  background-color: rgba(157, 196, 239, 0.2);
  color: #4a83dc;
}
.account .sec-profile {
  position: relative;
}
.account .sec-profile .share-btn {
  font-size: 1.5em;
  color: #0d59ab;
}
@media (max-width: 767.98px) {
  .account .sec-profile .share-btn {
    position: absolute;
    top: 0em;
    right: 0em;
  }
}
.account .post {
  display: block;
  background-color: rgba(157, 196, 239, 0.2);
  border-radius: 0.67em;
  color: #000;
  text-align: center;
  width: 124px;
  padding-bottom: 0.2em;
  max-width: 100%;
}
@media (max-width: 1469.98px) {
  .account .post {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    text-align: left;
    padding: 0.3em 0.6em;
  }
}
.account .post img {
  width: 110%;
  margin-top: -25%;
}
@media (max-width: 1469.98px) {
  .account .post img {
    width: 3em;
    margin-top: unset;
    margin-right: 0.5em;
  }
}
.account .btn-add-offer {
  background-color: rgba(157, 196, 239, 0.2);
  color: #9dc4ef;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 6.5em;
  border-radius: 10px;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  transition: 300ms ease-in-out;
}
.account .btn-add-offer:hover, .account .btn-add-offer:focus, .account .btn-add-offer:active {
  color: #4a83dc;
}
@media (max-width: 767.98px) {
  .account .btn-add-offer {
    font-size: 4.5em;
  }
}
@media (max-width: 575.98px) {
  .account .btn-add-offer {
    font-size: 3em;
  }
}
.account .add-offer {
  padding: 3em 2em;
  border-radius: 0.67em;
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
@media (max-width: 575.98px) {
  .account .add-offer {
    padding: 1.5em;
  }
}
.account .sec-feedback .user {
  width: 50%;
}
@media (max-width: 1289.98px) {
  .account .sec-feedback .user {
    width: 75%;
  }
}
@media (max-width: 767.98px) {
  .account .sec-feedback .user {
    width: 100%;
  }
}
.account .sec-feedback .user-photo {
  width: 60px;
  height: 60px;
}
.account .sec-feedback .user-main {
  height: 72px;
  margin-left: -20px;
  padding-left: 30px;
}
.account .sec-feedback .user-main .title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1em;
  height: 1em;
}
.account .sec-feedback .box {
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  border-radius: 0.67em;
  padding: 0.8em 1.3em;
}
.account .sec-feedback .info-list {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.account .sec-feedback .info-list li {
  margin-right: 1.5em;
  margin-bottom: 1em;
  border-bottom: none;
}
@media (max-width: 575.98px) {
  .account .sec-feedback .info-list li {
    margin-right: 1em;
    margin-bottom: 0.75em;
  }
}
.account .sec-finance .balance {
  background-color: #4a83dc;
  color: #ffffff;
  padding: 1em 1.2em;
  border-radius: 0.67em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.account .sec-messages {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: #ffffff;
  border: 1px solid rgba(157, 196, 239, 0.3);
  box-shadow: 0px 4px 15px 0px rgba(100, 100, 100, 0.08);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  border-radius: 0.67em;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
}
.account .sec-messages-list {
  width: 35%;
  border-right: 1px solid rgba(157, 196, 239, 0.3);
}
@media (max-width: 1469.98px) {
  .account .sec-messages-list {
    width: 40%;
  }
}
@media (max-width: 1289.98px) {
  .account .sec-messages-list {
    width: 100%;
    border-right: none;
  }
}
.account .sec-messages-list form {
  border-bottom: 1px solid rgba(157, 196, 239, 0.3);
}
.account .sec-messages-list ul {
  margin-top: 1.5em;
  list-style: none;
  padding-left: 0px;
}
.account .sec-messages-list .general-chat {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.75em 1.3em;
}
.account .sec-messages-list .general-chat .count {
  background-color: #1b1b1c;
  color: #ffffff;
  width: 4.8em;
  height: 4.8em;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 10px;
  text-align: center;
  line-height: 100%;
}
.account .sec-messages-list .general-chat h6, .account .sec-messages-list .general-chat .h6 {
  margin-left: 0.5em;
  flex: 1;
  text-align: left;
}
.account .sec-messages-chat {
  width: 65%;
  position: relative;
}
@media (max-width: 1469.98px) {
  .account .sec-messages-chat {
    width: 60%;
  }
}
@media (max-width: 1289.98px) {
  .account .sec-messages-chat {
    width: 100%;
  }
}
.account .sec-messages-chat .chat-window {
  height: 600px;
  height: 400px;
}
@media (max-width: 1469.98px) {
  .account .sec-messages-chat .chat-window {
    padding: 1.5em;
  }
}
@media (max-width: 1469.98px) {
  .account .sec-messages-chat .chat-form {
    padding: 0 1.5em;
  }
}
.account .sec-callback .box {
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  border-radius: 0.67em;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 3em 2em;
}
@media (max-width: 575.98px) {
  .account .sec-callback .box {
    padding: 1.5em;
  }
}
.account .sec-appeal .top {
  background-color: rgba(157, 196, 239, 0.2);
  border-radius: 0.67em 0.67em 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1.3em 2em;
}
@media (max-width: 575.98px) {
  .account .sec-appeal .top {
    padding: 1em 1.5em;
  }
}
.account .sec-appeal .box {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0 0 0.67em 0.67em;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  padding: 1.5em 2em;
}
.account .sec-appeal .box .chat-window {
  height: 550px;
}
.account .rouble {
  font-size: 1em;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.user {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
}
@media (max-width: 767.98px) {
  .user {
    flex-wrap: wrap;
  }
}
.user-photo {
  position: relative;
  z-index: 2;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
  border: 5px solid #ffffff;
  box-sizing: content-box;
}
@media (max-width: 1469.98px) {
  .user-photo {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 767.98px) {
  .user-photo {
    width: 90px;
    height: 90px;
  }
}
@media (max-width: 575.98px) {
  .user-photo {
    width: 70px;
    height: 70px;
  }
}
.user-photo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: relative;
  z-index: 0;
}
.user-photo::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0px;
  width: 100%;
  height: 50%;
  z-index: 1;
  background: linear-gradient(to bottom, transparent, #000 50%);
  background-size: cover;
}
.user-photo label {
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  bottom: 0.25em;
  color: #ffffff;
  font-size: 1.34em;
  transition: 300ms ease-in-out;
}
.user-photo label:hover {
  color: #48d868 !important;
}
.user-main {
  position: relative;
  z-index: 1;
  height: 144px;
  flex: 1;
  position: relative;
  margin-left: -60px;
  padding-left: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .user-main::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 70%;
    background-color: #ebf3fc;
    border-radius: 0.67em 0 0 0.67em;
  }
  .user-main::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 50%;
    background-color: #ebf3fc;
    transform: skewX(-25deg);
    border-radius: 0.67em;
  }
}
@media (max-width: 767.98px) {
  .user-main {
    background-color: rgba(157, 196, 239, 0.2);
    border-radius: 0.67em;
    padding-left: 65px;
    height: 120px;
  }
}
@media (max-width: 575.98px) {
  .user-main {
    height: 100px;
  }
}
.user-main .title {
  font-family: "Bebas";
  font-size: 2em;
  line-height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1em;
  max-height: 2em;
}
.user-info {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  margin-left: -1em;
  width: 215px;
}
@media (max-width: 1469.98px) {
  .user-info {
    width: 200px;
  }
}
@media (max-width: 767.98px) {
  .user-info {
    font-size: 0.9em;
    width: 100%;
    margin-left: 0em;
    margin-top: 1em;
    flex-direction: row;
    justify-content: center;
    gap: 1em;
  }
}
.user-info li {
  position: relative;
  padding: 0.6em;
  height: 41px;
  color: #1b1b1c;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
@media (min-width: 768px) {
  .user-info li:first-child {
    width: 79%;
  }
  .user-info li:nth-child(2) {
    width: 89.5%;
  }
  .user-info li::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 50%;
    background-color: #ffffff;
    transform: skewX(-25deg);
    border-radius: 0.67em;
  }
  .user-info li::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 70%;
    background-color: #ffffff;
    border-radius: 0 0.67em 0.67em 0;
  }
}
@media (max-width: 767.98px) {
  .user-info li {
    background-color: #ffffff;
    border-radius: 0.67em;
    flex-direction: column;
    height: auto;
    width: 23%;
  }
}
.user-info li div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
@media (max-width: 767.98px) {
  .user-info li div {
    flex-direction: column;
  }
}
.user-info li div svg {
  font-size: 1.6em;
  margin-right: 0.34em;
}
@media (max-width: 767.98px) {
  .user-info li div svg {
    color: #4a83dc;
  }
}
.user-info li div svg.svg {
  stroke-width: 1.3px;
}
.user-info li div svg.path path {
  stroke-width: 1.3px;
}
.user-info li:not(:first-child) {
  margin-top: 11px;
}
.user .qr-code {
  display: block;
  width: 100px;
  align-self: flex-start;
}
@media (max-width: 1289.98px) {
  .user .qr-code {
    width: 80px;
  }
}
@media (max-width: 767.98px) {
  .user .qr-code {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 10;
  }
}
@media (max-width: 575.98px) {
  .user .qr-code {
    width: 50px;
  }
}

.settings-line {
  padding: 1em;
  color: #000;
  display: grid;
  grid-template-columns: 150px 110px auto 140px 120px 60px;
  grid-template-rows: auto;
  grid-template-areas: "session system browser ip region btns";
  gap: 0.5em;
}
@media (max-width: 1289.98px) {
  .settings-line {
    grid-template-columns: 1fr 1fr 80px;
    grid-template-areas: "session system btns" "browser browser browser" "ip ip region";
    border: 1px solid #d9d9d9;
    border-radius: 0.67em;
  }
}
@media (max-width: 767.98px) {
  .settings-line {
    font-size: 0.8em;
    gap: 0.25em;
    grid-template-columns: 1fr 1fr 60px;
  }
}
@media (max-width: 575.98px) {
  .settings-line {
    font-size: 1em;
  }
}
.settings-line .session {
  grid-area: session;
}
.settings-line .system {
  grid-area: system;
  text-align: left;
}
@media (max-width: 1289.98px) {
  .settings-line .system {
    text-align: right;
  }
}
.settings-line .browser {
  grid-area: browser;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .settings-line .browser {
    text-align: left;
  }
}
.settings-line .ip {
  grid-area: ip;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .settings-line .ip {
    text-align: left;
  }
}
.settings-line .region {
  grid-area: region;
  text-align: center;
}
@media (max-width: 1289.98px) {
  .settings-line .region {
    text-align: right;
  }
}
.settings-line .btns {
  grid-area: btns;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
.settings-line .btns button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #4a83dc;
  font-size: 1.35em;
  transition: 300ms ease-in-out;
}
.settings-line .btns button:hover, .settings-line .btns button:focus {
  color: #66c6e4;
}
.settings-line .btns button:active {
  color: #0d59ab;
}

.offer-line-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 1em;
  color: #000;
}
@media (max-width: 1289.98px) {
  .offer-line-2 {
    flex-direction: column;
    align-items: stretch;
    border: 1px solid #d9d9d9;
    border-radius: 0.67em;
  }
}
.offer-line-2 .descr {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  max-height: 2.4em;
}
@media (max-width: 1289.98px) {
  .offer-line-2 .descr {
    margin-bottom: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2em;
    max-height: 4.8em;
  }
}
.offer-line-2 .price {
  width: 100px;
  text-align: right;
}
@media (max-width: 1289.98px) {
  .offer-line-2 .price {
    flex: 1;
  }
}
.offer-line-2 .btns {
  width: 60px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
.offer-line-2 .btns button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #4a83dc;
  font-size: 1.35em;
  transition: 300ms ease-in-out;
}
.offer-line-2 .btns button:hover, .offer-line-2 .btns button:focus {
  color: #66c6e4;
}
.offer-line-2 .btns button:active {
  color: #0d59ab;
}

.offer-line-3 {
  padding: 1em;
  color: #000;
  display: grid;
  grid-template-columns: 145px 110px 1fr 200px 130px 80px;
  grid-template-rows: 1fr;
  grid-template-areas: "date id descr seller status price";
}
@media (max-width: 1469.98px) {
  .offer-line-3 {
    grid-template-columns: 85px 90px 1fr 200px 130px 80px;
    font-size: 13px;
  }
}
@media (max-width: 1289.98px) {
  .offer-line-3 {
    border: 1px solid #d9d9d9;
    border-radius: 0.67em;
    font-size: 14px;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 0.5em;
    grid-template-areas: "descr descr" "id id" "status status" "seller date" "seller price";
  }
}
@media (max-width: 767.98px) {
  .offer-line-3 {
    font-size: 13px;
  }
}
.offer-line-3 > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.offer-line-3 .date {
  grid-area: date;
}
@media (max-width: 1469.98px) {
  .offer-line-3 .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1289.98px) {
  .offer-line-3 .date {
    align-items: flex-end;
  }
}
.offer-line-3 .id {
  grid-area: id;
}
.offer-line-3 .descr {
  grid-area: descr;
}
.offer-line-3 .seller {
  grid-area: seller;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.offer-line-3 .seller img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  margin-right: 0.75em;
}
@media (max-width: 1289.98px) {
  .offer-line-3 .seller img {
    width: 40px;
    height: 40px;
  }
}
.offer-line-3 .status {
  grid-area: status;
}
.offer-line-3 .price {
  grid-area: price;
}
@media (max-width: 1289.98px) {
  .offer-line-3 .price {
    justify-content: flex-end;
  }
}

.feedback-line {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.feedback-line img {
  --w: 50px;
  width: var(--w);
  height: var(--w);
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  border: 5px solid #fdfdfd;
  box-sizing: content-box;
  position: relative;
  z-index: 2;
  margin-top: 0.25em;
}
.feedback-line-main {
  position: relative;
  z-index: 1;
  flex: 1;
  margin-left: -40px;
}
.feedback-line-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0px 1em 0px 60px;
  margin-bottom: 0.5em;
}
@media (max-width: 575.98px) {
  .feedback-line-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 0px 1em 0px 45px;
  }
}
.feedback-line-text {
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  border-radius: 0.67em;
  padding: 1em 1em 1.3em 60px;
  font-weight: 300;
}
@media (max-width: 575.98px) {
  .feedback-line-text {
    padding: 1em 1em 1.3em 45px;
  }
}

.purchase-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2em;
  color: #000;
  background-color: #ffffff;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  border-radius: 0.67em;
  padding: 0.6em 1em;
}
@media (max-width: 1289.98px) {
  .purchase-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 1em;
    padding: 1em 1.5em;
  }
}
.purchase-line-text {
  flex: 1;
}
.purchase-line-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
.purchase-line-user img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
.purchase-line-user h6, .purchase-line-user .h6 {
  font-size: 1.067em;
  font-weight: 500;
  text-align: left;
}
.purchase-line-user button {
  display: flex;
  transition: 300ms ease-in-out;
}
.purchase-line-user button:hover, .purchase-line-user button:focus {
  transform: scale(1.3);
}
@media (max-width: 1289.98px) {
  .purchase-line-user {
    width: 100%;
  }
  .purchase-line-user div {
    flex: 1;
  }
}

.operation-line {
  padding: 1em;
  color: #000;
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
  grid-template-rows: 1fr;
  grid-template-areas: "date id type stat sum";
}
@media (max-width: 1289.98px) {
  .operation-line {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "date date date stat stat" "id type type sum sum";
    gap: 0.5em;
    font-size: 0.9em;
  }
}
@media (max-width: 767.98px) {
  .operation-line {
    gap: 1em 1em;
    font-size: 1em;
  }
}
.operation-line .date {
  grid-area: date;
}
.operation-line .id {
  grid-area: id;
}
@media (max-width: 1289.98px) {
  .operation-line .id {
    font-size: 0.9em;
    color: #8f8f8f;
  }
}
.operation-line .type {
  grid-area: type;
}
.operation-line .stat {
  grid-area: stat;
}
@media (max-width: 1289.98px) {
  .operation-line .stat {
    text-align: right;
  }
}
.operation-line .sum {
  grid-area: sum;
}
@media (max-width: 1289.98px) {
  .operation-line .sum {
    text-align: right;
  }
}

.appeal-line {
  background-color: #ffffff;
  border-radius: 0.67em;
  filter: drop-shadow(0px 4px 15px rgba(100, 100, 100, 0.08));
  padding: 1em;
  color: #000;
  display: grid;
  grid-template-columns: 35% 13% 22% 20% 10%;
  grid-template-rows: 1fr;
  grid-template-areas: "subject id status time btns";
}
@media (max-width: 1289.98px) {
  .appeal-line {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "date id" "subject subject" "status btns";
    gap: 0.5em;
  }
}
.appeal-line .subject {
  grid-area: subject;
}
.appeal-line .id {
  grid-area: id;
}
@media (max-width: 1289.98px) {
  .appeal-line .id {
    text-align: right;
  }
}
.appeal-line .status {
  grid-area: status;
}
.appeal-line time {
  grid-area: time;
}
.appeal-line .btns {
  grid-area: btns;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}

.dialog-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.75em 1.3em;
  border-radius: 0.67em;
  position: relative;
}
.dialog-preview.active {
  background-color: rgba(193, 193, 193, 0.2);
}
.dialog-preview img {
  width: 3em;
  height: 3em;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 0.5em;
  position: relative;
  z-index: 1;
}
.dialog-preview .indicator {
  position: absolute;
  bottom: 0.5em;
  left: 3.5em;
  width: 0.67em;
  height: 0.67em;
  border-radius: 50%;
  z-index: 10;
}
.dialog-preview .indicator.green {
  background-color: #48d868;
}
.dialog-preview .text {
  flex: 1;
}
.dialog-preview .text h6, .dialog-preview .text .h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 1.2em;
}
.dialog-preview .text time {
  font-weight: 300;
  color: #8f8f8f;
  font-size: 0.7em;
}
.dialog-preview .text p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 1.2em;
  font-weight: 300;
  color: #8f8f8f;
}

/***********
ACCOUNT END
***********/
.privacy-policy h4, .privacy-policy .h4,
.privacy-policy p {
  margin-bottom: 1em;
}
.privacy-policy h4:not(:first-child), .privacy-policy .h4:not(:first-child) {
  margin-top: 2em;
}

.rules {
  color: #000;
}
.rules hr {
  border: none;
  opacity: 1;
  height: 1px;
  background-color: rgba(157, 196, 239, 0.3);
  margin: 0.6em 0 1.2em;
}

.faq .box {
  background-color: #000;
  color: #ffffff;
  border-radius: 0.67em;
  position: relative;
  padding: 3em 40% 3em 2em;
}
.faq .box img {
  position: absolute;
  bottom: 0px;
  right: 5%;
  width: 35%;
  height: 110%;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  -o-object-position: center bottom;
     object-position: center bottom;
  z-index: 0;
}
.faq ul {
  list-style: none;
  padding-left: 0px;
}
.faq ul li {
  border-bottom: 1px solid rgba(157, 196, 239, 0.35);
  padding: 0.5em 0px;
  margin-bottom: 1em;
}
.faq ul li a {
  color: #4a83dc;
  font-weight: 600;
  transition: 300ms ease-in-out;
}
.faq ul li a:hover, .faq ul li a:focus, .faq ul li a:active {
  color: #0d59ab;
}

/* Footer */
footer {
  height: var(--f-height);
  background-color: #1b1b1c;
  color: #ffffff;
}
@media (max-width: 1109.98px) {
  footer {
    background-color: transparent;
    color: #1b1b1c;
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 10000;
  }
}
footer .dev-link {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 16px;
}
footer .dev-link svg {
  margin-left: 0.3em;
  font-size: 1.2em;
}
footer .nav-mobile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
footer .nav-mobile .logo {
  padding: 0px;
}
footer .nav-mobile .logo img {
  width: 70px;
}
footer .nav-mobile ul {
  list-style: none;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  flex: 1;
  background: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5), 1px 1px 10px rgba(100, 100, 100, 0.15);
  -webkit-backdrop-filter: blur(2.5px);
          backdrop-filter: blur(2.5px);
  margin-left: 1.7em;
  padding: 0.75em 1.5em;
  border-radius: 0.67em;
}
@media (max-width: 767.98px) {
  footer .nav-mobile ul {
    justify-content: space-around;
  }
}
@media (max-width: 575.98px) {
  footer .nav-mobile ul {
    justify-content: space-between;
  }
}
footer .nav-mobile ul li a,
footer .nav-mobile ul li button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.85em;
  color: #8f8f8f;
  transition: 300ms ease-in-out;
}
footer .nav-mobile ul li a svg path,
footer .nav-mobile ul li button svg path {
  stroke-width: 1px;
}
footer .nav-mobile ul li a.active,
footer .nav-mobile ul li button.active {
  color: #4a83dc;
}
footer .nav-mobile ul li a.active svg path,
footer .nav-mobile ul li button.active svg path {
  stroke-width: 1px;
}

/* Modal */
.modal-content {
  background-color: #ffffff;
  border: none;
  border-radius: 0.67em;
}
.modal-header {
  position: relative;
  height: 35px;
  padding: 0px;
  background-color: rgba(157, 196, 239, 0.2);
  border: none;
}
.modal-header .warning {
  position: absolute;
  right: 2em;
  bottom: 0.25em;
}
@media (max-width: 1109.98px) {
  .modal-header .warning {
    width: 20%;
  }
}
.modal-header .btn-close {
  background: url(imgs/close.svg) no-repeat center;
  background-size: contain;
  background-color: #ffffff;
  width: 1.34em;
  height: 1.34em;
  padding: 0px;
  margin: 0px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  isolation: isolate;
  transform: translateZ(0);
  transition: 300ms ease-in-out;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 1;
}
.modal-header .btn-close:hover, .modal-header .btn-close:focus {
  background-color: #0d59ab;
}
.modal-body {
  padding: 1.34em 2.68em;
}
@media (max-width: 575.98px) {
  .modal-body {
    padding: 1.34em;
  }
}

.offcanvas-body {
  position: relative;
}
.offcanvas-body ul {
  list-style: none;
  padding-left: 0px;
  background-color: linear-gradient(92.32deg, rgba(255, 255, 255, 0) 16.31%, rgba(245, 251, 255, 0.3) 97.01%), rgba(255, 255, 255, 0.3);
  border-radius: 0.67em;
  padding: 0 1em;
  font-size: 1.2em;
  box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.8), 0px 5px 25px rgba(95, 95, 95, 0.08);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.offcanvas-body ul li a {
  display: block;
  padding: 0.75em 0;
  color: #000;
}
.offcanvas-body ul li:not(:first-child) {
  border-top: 1px solid #f3f3f3;
}
.offcanvas-body .sec-promo h1, .offcanvas-body .sec-promo .h1 {
  font-size: 2.2em;
}
.offcanvas-body .sec-promo img {
  width: 40%;
  margin-top: -10%;
}
.offcanvas-body .dev-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.offcanvas-body .close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  color: #8f8f8f;
  transition: 300ms ease-in-out;
  padding: 0.5em;
}
.offcanvas-body .close:hover, .offcanvas-body .close:focus, .offcanvas-body .close:active {
  color: #1b1b1c;
}
.offcanvas-top {
  background-color: transparent;
  height: -moz-fit-content !important;
  height: fit-content !important;
}
.offcanvas-top .offcanvas-body {
  padding: calc(var(--h-height) + 1em) 1em 2em 1em;
  background-color: #ffffff;
  border-radius: 0px 0px 0.67em 0.67em;
}

.fav-modal {
  z-index: 1041;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  top: 65px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
}

.notification {
  box-sizing: border-box;
  padding: 13px 20px;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.1215686275), 0px 8px 24px -4px rgba(145, 158, 171, 0.2) !important;
  border-radius: 12px;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  font-size: 0.9em;
  position: relative;
  margin-bottom: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification-info {
  color: #2f96b4;
}

.notification-success {
  color: #36b37e;
}

.notification-warning {
  color: #f89406;
}

.notification-error {
  color: #bd362f;
}/*# sourceMappingURL=style.css.map */